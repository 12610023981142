import { PublicClientApplication } from "@azure/msal-browser";
const { REACT_APP_client_id, REACT_APP_authority_id } = process.env;
// import { REACT_APP_client_id, REACT_APP_authority_id } from "../../env";

export const pubClientApp = new PublicClientApplication({
  auth: {
    clientId: REACT_APP_client_id,
    authority: `https://login.microsoftonline.com/${REACT_APP_authority_id}/`,
    redirectUri: window.location.origin + "/management/dashboard",
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});
