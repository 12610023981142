export const styles = {
  search: {
    color: "#191919",
    width: "142px",
    height: "51px",
    backgroundColor: "#ffff",
    padding: "0.5rem 1.75rem 0.5rem 1.75rem ",
    fontFamily: "ToyotaType-Bold",
    fontSize: "1rem",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
};
