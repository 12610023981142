export const styles = {
  footer: {
    fontFamily: "ToyotaType-Regular",
    // position: "fixed",
    // bottom: "0px",
    // width: "100%",
    // textAlign: "right",
    // left: "0px",
    // padding: "1rem",
    fontSize: "1rem",
  },
};
