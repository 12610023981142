import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { Grid, Typography } from "@mui/material";

export const BatteryFailedCharts = () => {
  const { graphFailedCount, failedgraphError } = useAppContext();
  const chartRef = useRef(null);

  const [options, setOptions] = useState({});

  const colorMap = { TMC: "#eb0a1e", STELLANTIS: "#9B9999" };

  // useEffect(() => {
  //   if (chartRef && chartRef.current) {
  //     const chart = chartRef.current.chart;

  //     const tmcData = [
  //       120, 180, 20, 180, 500, 250, 70, 300, 220, 350, 700, 380,
  //     ];
  //     const stlData = [90, 120, 150, 50, 160, 180, 120, 240, 260, 30, 300, 200];

  //     chart.addSeries({
  //       name: "TMC",
  //       color: "#eb0a1e",
  //       data: tmcData,
  //     });

  //     chart.addSeries({
  //       name: "Stellantis",
  //       color: "#9B9999",
  //       data: stlData,
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchGraphBatteriesFailedCount();
  //   console.log(graphFailedCount);
  // }, []);
  // useEffect(() => {
  //   console.log("reportData", graphFailedCount);
  // }, [graphFailedCount]);
  // const options = {
  //   chart: {
  //     type: "column",
  //   },
  //   credits: { enabled: false },
  //   title: {
  //     text: "Batteries Failed to Upload",
  //     align: "left",
  //   },
  //   xAxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     crosshair: true,
  //     accessibility: {
  //       description: "Months",
  //     },
  //   },
  //   yAxis: {
  //     min: 0,
  //     title: {
  //       text: "",
  //     },
  //   },
  //   legend: {
  //     align: "right",
  //     verticalAlign: "top",
  //     symbolRadius: 0,
  //     symbolHeight: 10,
  //     symbolWidth: 12,
  //   },
  //   tooltip: {
  //     valueSuffix: " Records",
  //   },
  //   plotOptions: {
  //     column: {
  //       pointPadding: 0.2,
  //       borderWidth: 0,
  //     },
  //   },
  //   series: [],
  // };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      // const chart = chartRef.current.chart;
      // graphAddedCount?.forEach((res) => {
      //   console.log("sadas", "====");
      //   chart.addSeries({
      //     name: res.source,
      //     color: colorMap[res.source],
      //     data: res.data,
      //   });
      // });
    }

    setOptions(() => {
      return {
        chart: {
          type: "column",
        },
        credits: { enabled: false },

        title: {
          text: "Batteries Failed to Upload",
          align: "left",
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          crosshair: true,
          accessibility: {
            description: "Months",
          },
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            text: "",
          },
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          symbolRadius: 0,
          symbolHeight: 10,
          symbolWidth: 12,
        },
        tooltip: {
          valueSuffix: " Records",
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
            groupPadding: 0,
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y > 0) return this.y;
              },
            },
            // distance: 20,
          },
        },
        series: graphFailedCount?.map((res) => {
          return {
            name: res.source,
            color: colorMap[res.source],
            data: res.data,
          };
        }),
      };
    });
  }, [graphFailedCount]);

  if (failedgraphError) {
    return (
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">
          Error : {failedgraphError ? failedgraphError : "Something Went Wrong"}
        </Typography>
      </Grid>
    );
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
};
