import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAppContext } from "../../../context/AppContext";
import { theme } from "../../../styles/theme";
import { styles } from "./styles";

// const CssTextField = styled(TextField)({
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#ffff",
//   },
//   "& .label": {
//     borderBottomColor: "#ffff",
//   },
// });

const CssTextField = styled(TextField)({
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline": {
    borderBottomColor: "#ffff",
  },

  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffff",
    },
  },
});

export const InputField = (props) => {
  const { vinNumber, setVinNumber } = useAppContext();
  const handleChange = (e) => {
    setVinNumber(e.target.value);
  };
  return (
    <CssTextField
      onChange={handleChange}
      variant="outlined"
      label="17-Digit VIN number"
      sx={styles.inputField}
      value={vinNumber}
      inputProps={{
        style: {
          color: theme.custom.textFieldColor,
        },
      }}
    />
  );
};
