import { useIsAuthenticated } from "@azure/msal-react";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import img from "../assets/images/Background_image.jpg";
import { Footer } from "../components/atoms/Footer/Footer";
import { PopupDataUpload } from "../components/atoms/PopupDataUpload/PopupDataUpload";
import Hamburger from "../components/molecules/Hamburger/Hamburger";
import { Navbar } from "../components/organisms/Navbar/Navbar";
import { useAppContext } from "../context/AppContext";

const MainLayout = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState("");

  const isAuthenticated = useIsAuthenticated();

  const location = useLocation();

  const [style, setStyle] = useState({});
  const { authError } = useAppContext();

  useEffect(() => {
    setStyle(() => {
      return window.location.pathname.split("/")[1] === "bin" ||
        window.location.pathname.split("/")[1] === "batterymodel"
        ? {}
        : {
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          };
    });
  }, [location]);

  return (
    <>
      <Grid
        container
        sx={{
          ...style,
          height: "100vh",
          overflow: "auto",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        <Navbar />
        <Grid
          item
          sx={{
            flexGrow: 1,
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "calc(100vh - 36px)",
            overflowX: "hidden",
          }}
        >
          {isAuthenticated && !authError ? <Hamburger /> : null}
          <Grid
            sx={(theme) => ({
              flexGrow: 1,
              height: "100%",
              paddingTop: "68px",
              [theme.breakpoints.down("sm")]: {
                paddingTop: "52px",
              },
            })}
          >
            {children}
          </Grid>
        </Grid>
        <Footer
          setUploadSuccess={setUploadSuccess}
          setShowPopup={setShowPopup}
        />
      </Grid>
      <PopupDataUpload
        open={showPopup}
        setOpen={setShowPopup}
        type={
          uploadSuccess === "pass"
            ? "success"
            : uploadSuccess === "failedPrivacyPolicy"
            ? "failedPrivacyPolicy"
            : uploadSuccess === "failedFaq"
            ? "failedFaq"
            : "failed"
        }
      />
    </>
  );
};

export default MainLayout;
