import { Grid } from "@mui/material";
import { BatterData } from "../../components/organisms/BatteryData/BatteryData";
import { styles } from "./styles";
export const BatteryDataReport = () => {
  return (
    <Grid sx={styles.batteryDataReport}>
      <BatterData />
    </Grid>
  );
};
