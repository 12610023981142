import { Divider } from "@mui/material";

export const HorizontalSeperator = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        width: "1px",
        height: "32px",
        background: "#2e2e2e",
        margin: "0",
      }}
    />
  );

  // return <Typography sx={styles.seperator}>|</Typography>;
};
