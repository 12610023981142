export const styles = {
  batteryStatusReport: {
    marginLeft: "5px",
    height: "100%",
    overflow: "auto",
  },
  // batteryStatus:{
  //   container sx={{ position: "relative", height: "100%" }}
  // }
};
