import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { BatteryFailedCharts } from "../../components/atoms/BatteryFailedCharts/BatteryFailedCharts";
import { BatteryUploadCharts } from "../../components/atoms/BatteryUploadCharts/BatteryUploadCharts";
import { SourceDropDown } from "../../components/atoms/SourceDropDown/SourceDropDown";
import { YearDropDown } from "../../components/atoms/YearDropDown/YearDropDown";
import { BinVinAdminSearch } from "../../components/molecules/BinVinAdminSearch/BinVinAdminSearch";
import NBPStats from "../../components/organisms/NBPStats/NBPStats";
import { useAppContext } from "../../context/AppContext";
import { styles } from "./styles";
// import { SourceDropDown } from "../../components/atoms/SourceDropDown/SourceDropDown";
// import MenuItem from "@mui/material";

export const Dashboard = () => {
  const {
    sourceProcessingStatistics,
    fetchGraphBatteriesAddedCount,
    yearProcessingStatistics,
    fetchGraphBatteriesFailedCount,
    sourceProcessed,
    timeProcessed,
    fetchBatteryProcessedCount,
    setBinError,
  } = useAppContext();

  // useEffect(() => {
  //   const currentAccount = instance.getActiveAccount();
  //   if (currentAccount) {
  //     setDisplayData(currentAccount.username);
  //   }
  // }, [instance]);

  // <>
  //   <InputField />
  //   {displayData ? (
  //     <>
  //       <h1>{displayData}</h1>{" "}
  //     </>
  //   ) : null}
  // </>
  // useEffect(() => {
  //   fetchAccessTokenRequest();
  // }, []);
  useEffect(() => {
    setBinError("");
  }, []);

  useEffect(() => {
    fetchGraphBatteriesAddedCount();
    fetchGraphBatteriesFailedCount();
  }, [sourceProcessingStatistics, yearProcessingStatistics]);

  useEffect(() => {
    fetchBatteryProcessedCount();
  }, [sourceProcessed, timeProcessed]);

  // if (loading) {
  //   return (
  //     <Grid
  //       sx={{
  //         width: "100vw",
  //         height: "100vh",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Grid>
  //   );
  // }

  return (
    <Grid sx={styles.dashboard}>
      {/* <Grid
        sx={{
          display: "flex",
          // border: "2px solid yellow",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      > */}
      {/* <BaseLayout> */}
      {/* <Grid
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "15px",
          }}
        > */}
      <Grid container sx={{ position: "relative", height: "100%" }}>
        <Grid
          container
          direction="column"
          gap={"25px"}
          padding={"15px 0px 0px 0px"}
          sx={{
            position: "absolute",
            top: 0,
            height: "90%",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              height: "100%",
              // overflow: "auto",
              // border: "1px solid white",
              // alignItems: "center",
              // marginLeft: "-20px",
              // justifyContent: "space-between",
              // gap: "15px",
            }}
          >
            <Grid
              sx={{
                // border: "3px solid red",
                display: "flex",
                flexDirection: "column",
                paddingRight: "1rem",
                // justifyContent: "center",
                // alignItems: "center",
                gap: "0.75rem",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  // border: "5px solid blue",
                  // width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  // padding: "1rem",
                  // maxHeight: "50%",
                }}
              >
                <BinVinAdminSearch />
                <Typography variant="h6" sx={{ color: "white" }}>
                  Data Processing Statistics
                </Typography>
                <Grid
                  sx={{
                    backgroundColor: "white",
                    padding: "1rem",
                    // gap: "1rem",
                    borderRadius: "1rem",
                    // border: "3px solid yellow",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      width: "632px",
                      gap: "1.75rem",
                      height: "3rem",
                    }}
                  >
                    <SourceDropDown />
                    <YearDropDown />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      paddingTop: "1rem",
                      justifyContent: "space-evenly",
                      height: "19rem",
                    }}
                  >
                    <BatteryUploadCharts />
                    <BatteryFailedCharts />
                  </Grid>
                </Grid>
              </Grid>

              <NBPStats />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
      {/* </BaseLayout> */}
    </Grid>
    // </Grid>
  );
};
