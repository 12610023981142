import { Dialog, DialogContent, Typography } from "@mui/material";
import success from "./SuccessfullyUploaded_icon.png";
import failed from "./UploadFailure_icon.png";

export const PopupDataUpload = ({ open, setOpen, type = "success" }) => {
  const successMessage = "Successfully Uploaded !";
  const failedMessage = "Not Uploaded";
  const failedTemplate = "Wrong file template";
  const failedExcedMessage = "Download limit of 10000 Battery Record exceeds ,";
  const failedExceedMessageLine2 =
    "Kindly contact DBP Support team to extract bulk data";

  const failedPrivacyPolicy = "Privacy Policy is not available";
  const failedFaq = "FAQ is not available";

  const successContact1 = "Message recieved sucessfully.";
  const successContact =
    "Thank you for contacting us. We will get back to you soon.";
  const failedContact = "Failed to send message. Please try again later.";
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "16px" } }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // gap: "24px",
          padding: "54px 24px",
        }}
      >
        <img
          src={
            type == "success"
              ? success
              : type == "successContact"
              ? success
              : failed
          }
          alt="successimage"
          style={{
            height: "auto",
            width: "80px",
          }}
        />
        <Typography component="span">
          {type == "success"
            ? successMessage
            : type == "successContact"
            ? successContact1
            : type == "failedContact"
            ? failedContact
            : type == "failedPrivacyPolicy"
            ? failedPrivacyPolicy
            : type == "failedFaq"
            ? failedFaq
            : type == "failedTemplate"
            ? failedTemplate
            : type == "failedExcedMessage"
            ? failedExcedMessage
            : failedMessage}
        </Typography>
        <Typography component="span">
          {type == "failedExcedMessage" ? failedExceedMessageLine2 : ""}
        </Typography>
        <Typography component="span">
          {type == "successContact" ? successContact : ""}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
