import ToyotaLogoIcon from "./TOYOTA_LOGO_ICON.png";
export const ToyotaLogo2 = () => {
  return (
    <img
      src={ToyotaLogoIcon}
      style={{ width: "auto", height: "28px" }}
      alt="Toyota_Logo"
    />
  );
};
