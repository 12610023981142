// import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import TOYOTA_LOGO from "./TOYOTA_LOGO.png";

export const ToyotaLogo = () => {
  const navigate = useNavigate();
  return (
    <img
      src={TOYOTA_LOGO}
      alt="TOYOTALOGO"
      onClick={() => navigate("/")}
      style={{ cursor: "pointer" }}
    />
  );
};
