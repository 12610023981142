import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppContext } from "./context/AppContext";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("idToken");
  const { authError } = useAppContext();
  useEffect(() => {
    // You can use this useEffect to set a loading state until MSAL authentication status is determined
    if (
      (inProgress === "none" && token && accounts?.length > 0) ||
      (inProgress === "none" && accounts?.length === 0 && !token)
    ) {
      setLoading(false);
    }
  }, [inProgress, token, accounts]);

  if (loading) {
    return (
      <Grid
        sx={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return isAuthenticated && !authError ? (
    <Outlet />
  ) : (
    <>
      <Navigate to="/" />
    </>
  );
};

export default ProtectedRoute;
