import { Button, Typography } from "@mui/material";
import { useState } from "react";
import drag from "./Drag&drop_icon.png";

export const DragDrop = (props) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (props.type === "pdf") {
      if (file && file.type === "application/pdf") {
        if (file.size <= 5 * 1024 * 1024) {
          props.setSelectedFile(file);
          setErrorMessage("");
        } else {
          props.setSelectedFile(null);
          setErrorMessage("Maximum permissible file size is 5 MB ");
        }
      } else {
        props.setSelectedFile(null);
        setErrorMessage("Wrong file type");
      }
    }
    if (props.type === "xlsx") {
      if (file) {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        if (fileExtension === "xlsx") {
          if (file.size <= 5 * 1024 * 1024) {
            props.setSelectedFile(file);
            setErrorMessage("");
          } else {
            props.setSelectedFile(null);
            setErrorMessage("Maximum permissible file size is 5 MB ");
          }
        } else {
          props.setSelectedFile(null);
          setErrorMessage("Wrong file type");
        }
      }
    }
  };

  const handleButtonClick = () => {
    document.getElementById(`fileInput-${props.type}`).click();
  };

  const handleDropOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (props.type === "pdf") {
      if (file && file.type === "application/pdf") {
        if (file.size <= 5 * 1024 * 1024) {
          props.setSelectedFile(file);
          setErrorMessage("");
        } else {
          props.setSelectedFile(null);
          setErrorMessage("Maximum permissible file size is 5 MB ");
        }
      } else {
        props.setSelectedFile(null);
        setErrorMessage("Wrong file type");
      }
    } else if (props.type === "xlsx") {
      if (file) {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts?.length - 1].toLowerCase();
        if (fileExtension === "xlsx") {
          if (file.size <= 5 * 1024 * 1024) {
            props.setSelectedFile(file);
            setErrorMessage("");
          } else {
            props.setSelectedFile(null);
            setErrorMessage("Maximum permissible file size is 5 MB ");
          }
        } else {
          props.setSelectedFile(null);
          setErrorMessage("Wrong file type");
        }
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.5rem",
        height: "50%",
      }}
      onDrop={handleDrop}
      onDragOver={handleDropOver}
    >
      <img src={drag} alt="DragDropIcon.png" />
      <Typography>Drag & Drop the file here</Typography>
      <Typography> OR </Typography>

      <input
        type="file"
        id={`fileInput-${props.type}`}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <label htmlFor={`fileInput-${props.type}`}>
        <Button
          sx={{ display: "block", color: "black", border: "1px solid black" }}
          onClick={handleButtonClick}
        >
          Browse File
        </Button>
      </label>
      {props.selectedFile?.name && (
        <Typography style={{ color: "green" }}>
          Selected File: {props.selectedFile.name}
        </Typography>
      )}
      {errorMessage && (
        <Typography style={{ color: "red" }}>{errorMessage}</Typography>
      )}
      {props.error?.selectedFile && (
        <Typography variant="body2" sx={{ color: "red", paddingTop: "1rem" }}>
          Please select the file
        </Typography>
      )}
    </div>
  );
};
