import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useAppContext } from "../../../context/AppContext";
import { styles } from "./styles";

export const LogoutButton = () => {
  const { handleLogout } = useAppContext();

  // const handleLogout = async () => {
  //   try {
  //     localStorage.removeItem("idToken");
  //     await instance.logout();
  //   } catch (error) {}
  // };

  return (
    <Stack direction={"row"} spacing={2}>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        size="large"
        sx={styles.logoutBtn}
        onClick={handleLogout}
      >
        Logout
      </Button>
      <Avatar>
        <PersonIcon />
      </Avatar>
    </Stack>
  );
};
