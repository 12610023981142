import { useMsal } from "@azure/msal-react";
import LoginIcon from "@mui/icons-material/Login";
import { Button, Stack, Tooltip } from "@mui/material";
import { styles } from "./styles";

export const LoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.loginRedirect({
        scopes: ["user.read"],
      });
    } catch (error) {}
  };

  return (
    <Stack direction={"row"} spacing={2}>
      <Tooltip title="Login for TME internal users">
        <Button sx={styles.loginBtn} onClick={handleLogin}>
          <LoginIcon />
        </Button>
      </Tooltip>
    </Stack>
  );
};
