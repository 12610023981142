import { Typography } from "@mui/material";
import { styles } from "./styles";
import { WelcomeConst } from "../../../assets/consts/WelcomeConst";

export const WelcomeHeading = () => {
  return (
    <Typography variant="h4" sx={styles.dbpheading}>
      {WelcomeConst}
    </Typography>
  );
};
