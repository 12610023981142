import { Link } from "@mui/material";
import { FindVinConst } from "../../../assets/consts/FindVinConst";
import { styles } from "./styles";
export const FindVin = (props) => {
  return (
    <Link
      component={"button"}
      onClick={props.handleOpen}
      sx={styles.findVin}
      underline="hover"
    >
      {FindVinConst}
    </Link>
  );
};
