export const styles = {
  dialogBox: {
    borderRadiues: "15px",
    "& .MuiPaper-root": {
      maxHeight: "90vh",
      maxWidth: "85vw",
    },
    vinLabel: {
      textTransform: "capitilize",
    },
    findLabel: {
      textTransform: "capitalize",
    },
    vehicleLabel: {
      textTransform: "capitalize",
    },
    vinLabels: {
      color: "red",
    },
    exTyp: {
      fontFamily: "ToyotaType-BoldIt",
    },
    "& .MuiAccordionSummary-content": {
      margin: "24px 0 0 0 !important",
    },
    "& .MuiAccordion-root": {
      margin: "0 !important",
    },
  },
};
