import { useEffect } from "react";
import "./App.css";
import { MainRouter } from "./MainRouter";
import { AppProvider } from "./context/AppContext";
import { MsalProvider } from "@azure/msal-react";

function App({ msalInstance }) {
  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <AppProvider>
          <MainRouter />
        </AppProvider>
      </MsalProvider>
    </div>
  );
}



const scriptUrlArray = [
    { src: process.env.REACT_APP_SCRIPT_SDK, type: "text/javascript", charset: "UTF-8", "data-domain-script": process.env.REACT_APP_SCRIPT_SDK_DOMIN }
  ];

const addScript = ((scriptInfo) => {
  if (!document.querySelector(`script[src="${scriptInfo.src}"]`)) {

  const scriptElement = document.createElement("script");
  Object.keys(scriptInfo).forEach((key) => {
    scriptElement.setAttribute(key, scriptInfo[key]);
  });
  document.head.appendChild(scriptElement);
  return scriptElement;
}
return null
});
const addedScripts = scriptUrlArray.map(scriptInfo => addScript(scriptInfo)).filter(script => script !== null);

// Inline script
const inlineScript = document.createElement("script");
inlineScript.type = "text/javascript";
inlineScript.textContent = "function OptanonWrapper() { }";
document.head.appendChild(inlineScript);

// Load OTAutoBlock, and afterwards the Adobe scripts
window.requirees(process.env.REACT_APP_SCRIPT_COOKIE).then(() => {
  window.require(process.env.REACT_APP_SCRIPT_URL);
});

export default App;
