import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Grid from "@mui/material/Grid";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";

// const ListItemName = [
//   "Dashboard",
//   "BatteryData Report",
//   "Battery Status Report",
//   "Manual Data Upload",
//   "Configuration",
// ];

const ListItemName = [
  {
    label: "Dashboard",
    value: "dashboard",
  },

  {
    label: "Battery Data Report",
    value: "batterydatareport",
  },
  {
    label: "Upload Status Report",
    value: "batterystatusreport",
  },
  {
    label: "Manual Data Upload",
    value: "manualdataupload",
  },
];

export const MenuList = ({ onClose }) => {
  const navigate = useNavigate();
  const handleItemClick = (path) => {
    navigate(`/management/${path}`);
    onClose(); // Close the menu after navigation
  };
  return (
    <Box sx={styles.drawerList}>
      <div>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={10}>
            <List>
              {ListItemName.map((text, i) => (
                <ListItem key={i}>
                  <ListItemButton onClick={() => handleItemClick(text.value)}>
                    <ListItemText
                      primary={text.label}
                      disableTypography
                      sx={styles.listItemText}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={onClose}>
              <CancelOutlinedIcon sx={{ color: "white" }} fontSize="large" />
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
