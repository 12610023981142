import { Grid, Typography } from "@mui/material";
import UploadDropDown from "../../atoms/UploadDropDown/UploadDropDown";
import downloadTemplateIcon from "./DownloadTemplate_icon.png";
import axios from "axios";
// import { REACT_APP_base_url } from "../../../env";

export const SourceDoc = (props) => {
  const { REACT_APP_base_url } = process.env;

  const downloadTemplate = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_base_url}/battery-service/v1/batteries/data-template?source=tmc`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          },
        }
      );
      const contentDisposition = response.headers["content-disposition"];
      let filename = "report.csv"; // Default filename

      if (contentDisposition) {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const match = regex.exec(contentDisposition);
        if (match !== null && match[1]) {
          filename = match[1].replace(/['"]/g, "").trim();
        } else {
          console.warn("Filename not found in Content-Disposition header.");
        }
      } else {
        console.warn("Content-Disposition header not found.");
      }

      // Create Blob URL and initiate file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading failed report:", error);
    }
  };
  return (
    <Grid
      sx={{
        display: "flex",
        gap: "1rem",
        padding: "0rem 1rem 0 1rem",
        justifyContent: "space-between",
        height: "80px",
      }}
    >
      {props.type === "pdf" ? (
        <>
          <div>
            <UploadDropDown
              inputLabel={"Documents"}
              source={props.documents}
              //   processedData={documents}
              sourceValue={props.docValue}
              setSourceValue={props.setDocValue}
            />
            {props.error?.docValue && (
              <Typography
                variant="body2"
                sx={{ color: "red", paddingTop: "1rem" }}
              >
                Please select the Document name before uploading the file
              </Typography>
            )}
          </div>
          <div>
            <UploadDropDown
              inputLabel={"Source"}
              source={props.source}
              sourceValue={
                props.docValue === "policy" || props.docValue === "faq"
                  ? ""
                  : props.sourceValue
              }
              setSourceValue={props.setSourceValue}
              disabled={props.docValue === "policy" || props.docValue === "faq"}
              //   processedData={}
            />
            {props.docValue !== "policy" && props.docValue !== "faq" && (
              <>
                {props.error?.sourceValue && (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", paddingTop: "1rem" }}
                  >
                    Please select the source before uploading the file
                  </Typography>
                )}
              </>
            )}
          </div>
        </>
      ) : props.type === "xlsx" ? (
        <>
          <div>
            <UploadDropDown
              inputLabel={"Source"}
              source={props.source}
              sourceValue={props.sourceValue}
              setSourceValue={props.setSourceValue}
              //   processedData={}
            />
            {props.error?.sourceValue && (
              <Typography
                variant="body2"
                sx={{ color: "red", paddingTop: "1rem" }}
              >
                Please select the source before uploading the file
              </Typography>
            )}
          </div>
          {props.type === "pdf" ? (
            <div>
              <UploadDropDown
                inputLabel={"Documents"}
                source={props.documents}
                //   processedData={documents}
                sourceValue={props.docValue}
                setSourceValue={props.setDocValue}
              />
              {props.error?.docValue && (
                <Typography
                  variant="body2"
                  sx={{ color: "red", paddingTop: "1rem" }}
                >
                  Please select the Document name before uploading the file
                </Typography>
              )}
            </div>
          ) : props.type === "xlsx" ? (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={downloadTemplate}
              >
                <img
                  src={downloadTemplateIcon}
                  alt="DownLoadTemplateIcon.png"
                />
                <Typography>Download Template</Typography>
              </div>
            </Grid>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};
