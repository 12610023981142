import SortIcon from "@mui/icons-material/Sort";
import { Grid, Icon } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import { MenuList } from "./MenuList";

export default function Hamburger() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Grid style={{ display: "flex", cursor: "pointer" }}>
      {/* <Button
        onClick={toggleDrawer(true)}
        style={{
          padding: "0px",
          margin: "0px",
          alignItems: "flex-start",
          border: "1px solid white",
          
        }}
      > */}
      {/* If you want to get hover element effect add onMouseEnter={toggleDrawer(true)} instead of onClick  in the Button*/}
      <Icon>
        <SortIcon
          onClick={toggleDrawer(true)}
          sx={{ color: "#eb0a1e", fontSize: "2rem" }}
        />
      </Icon>
      {/* </Button> */}
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          opacity: 0.8,
        }}
      >
        <MenuList onClose={toggleDrawer(false)} />
      </Drawer>
    </Grid>
  );
}
