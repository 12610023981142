import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";

// const WhiteSelect = styled(Select)({
//   "& .MuiSelect-select, & .MuiInputLabel-root": {
//     color: "white", // Text and label color
//   },
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor: "white", // Border color
//   },
//   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//     borderColor: "rgb(255, 255, 255)",
//   },
//   "& .MuiSvgIcon-root": {
//     fill: "white", // Icon color
//   },
//   "&:focus": {
//     backgroundColor: "white", // Focused background color
//   },
//   "&:hover": {
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "white", // Hover border color
//     },
//   },
// });

function UploadDropDown(props) {
  const handleChange = (event) => {
    // props.setProcessedData(event.target.value);
    props.setSourceValue(event.target.value);
  };

  return (
    <Box sx={{ width: "20rem" }}>
      <FormControl fullWidth>
        <InputLabel
          style={{}} // Set label color
        >
          {props.inputLabel}
        </InputLabel>
        <Select
          value={props.sourceValue}
          label={props.inputLabel}
          onChange={handleChange}
          variant="outlined"
          disabled={props.disabled}
        >
          {props.source.map((source, i) => (
            <MenuItem key={i} value={source.value}>
              {source.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default UploadDropDown;
