import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
// import { REACT_APP_base_url } from "../../../env";
import { PopupDataUpload } from "../../atoms/PopupDataUpload/PopupDataUpload";

function ContactUsForm(props) {
  const { REACT_APP_base_url } = process.env;

  const { open, setOpen } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [disabled, setDisabled] = useState(false);

  const initialFormState = {
    email: "",
    name: "",
    enquiryType: "",
    message: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState({
    email: false,
    enquiryType: false,
    message: false,
  });

  const handleClose = () => {
    setOpen(false);
    setFormState(initialFormState);
  };

  const handleKeyDown = (event) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(event.key) && event.key.length === 1) {
      event.preventDefault();
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // if (name === "name") {
    //   if (value) {
    //     const isValidName = /^[a-zA-Z\s]*$/.test(value);
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       name: isValidName ? "" : "Invalid name format",
    //     }));
    //   } else {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       name: "",
    //     }));
    //   }
    // }

    if (name === "email") {
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Email is required",
        }));
      } else {
        try {
          await yup.string().email("Invalid email format").validate(value);
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        } catch (error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: error.message,
          }));
        }
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value ? "" : `${name} is required`,
      }));
    }
  };
  const handleSend = async () => {
    if (!formState.email || !formState.enquiryType || !formState.message) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: formState.email ? prevErrors.email : "Email is required",
        enquiryType: !formState.enquiryType,
        message: !formState.message,
      }));
    } else {
      try {
        await yup
          .string()
          .email("Invalid email format")
          .validate(formState.email);
        const hasErrors = Object.values(errors).some((error) => error === true);
        if (!hasErrors) {
          setDisabled(true);

          const response = await fetch(
            `${REACT_APP_base_url}/battery-service-batch/public/v1/jobs/operation/sendQuery`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: formState.email,
                name: formState.name,
                subject: formState.enquiryType,
                body: formState.message,
              }),
            }
          );

          if (!response.ok) {
            setDisabled(false);

            setUploadSuccess("failed");
            setShowPopup(true);
          } else {
            setDisabled(false);

            setUploadSuccess("pass");
            setShowPopup(true);
          }
          handleClose();
          setFormState(initialFormState);
        }
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: error.message,
        }));
      }
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: { width: "80%", height: "70%" }, // Change the background color to grey
        }}
      >
        <DialogTitle id="form-dialog-title" sx={{ textAlign: "center" }}>
          Contact Us
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={formState.name}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            // error={Boolean(errors.name)}
            // helperText={errors.name}
          />
          <TextField
            error={Boolean(errors.email)}
            helperText={errors.email}
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            required
            value={formState.email}
            onChange={handleChange}
          />
          <FormControl
            fullWidth
            required
            margin="dense"
            error={Boolean(errors.enquiryType)}
            variant="outlined"
          >
            <InputLabel id="enquiryType">Enquiry Type</InputLabel>
            <Select
              name="enquiryType"
              value={formState.enquiryType}
              onChange={handleChange}
              label="Enquiry Type"
            >
              <MenuItem value={"Enquiry about battery information"}>
                Enquiry about battery information
              </MenuItem>
              <MenuItem value={"Enquiry about technical errors"}>
                Enquiry about technical errors
              </MenuItem>
              <MenuItem value={"Other enquiry"}>Other enquiry</MenuItem>
            </Select>
            {errors.enquiryType && (
              <FormHelperText>Enquiry type is required</FormHelperText>
            )}
          </FormControl>
          <TextField
            error={Boolean(errors.message)}
            helperText={errors.message ? "Message is required" : ""}
            margin="dense"
            name="message"
            label="Message"
            type="text"
            fullWidth
            multiline
            rows={6}
            required
            value={formState.message}
            onChange={handleChange}
            inputProps={{ maxLength: 1000 }}
            placeholder="Max 1000 characters"
          />
        </DialogContent>

        <Grid
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down(500)]: {
              flexDirection: "column",
              gap: "4px",
            },
            // textAlign: "right",
          })}
        >
          <Typography
            sx={(theme) => ({
              fontSize: "1rem",
              paddingLeft: "12px",
              [theme.breakpoints.down(500)]: {
                fontSize: "1rem",
              },
              // textAlign: "right",
            })}
          >
            <b>*</b> Mandatory field
          </Typography>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                color: "white",
                width: "124px",
                height: "51px",
                backgroundColor: "grey",
                padding: "0.5rem 1.75rem 0.5rem 1.75rem ",
                fontFamily: "ToyotaType-Bold",
                fontSize: "1rem",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "grey",
                  color: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSend}
              sx={{
                color: "white",
                width: "124px",
                height: "51px",
                backgroundColor: "grey",
                padding: "0.5rem 1.75rem 0.5rem 1.75rem ",
                fontFamily: "ToyotaType-Bold",
                fontSize: "1rem",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "grey",
                  color: "black",
                },
              }}
              disabled={disabled}
            >
              Send
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <PopupDataUpload
        open={showPopup}
        setOpen={setShowPopup}
        type={
          uploadSuccess == "pass"
            ? "successContact"
            : uploadSuccess == "failed"
            ? "failedContact"
            : "failed"
        }
      />
    </div>
  );
}

export default ContactUsForm;
