import React from 'react';

export const CookiesPage = () => {
  return (
    <div style={{ color: 'white' }}>
      <h1>Cookies Policy</h1>
        <div style={{ background: 'none' }} id="ot-sdk-cookie-policy">
        </div>
 </div>
  );
};