import { Container, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import { sources, useAppContext } from "../../../context/AppContext.jsx";
import BatteryDropDown from "../../atoms/BatteryDropDown/BatteryDropDown.jsx";
import BatteryStats from "../../atoms/BatteryStats/BatteryStats.jsx";
import MultiSelect from "../../atoms/MultiSelect/MultiSelect.jsx";
import { styles } from "./styles.js";

function NBPStats() {
  const {
    sourceProcessed,
    timeProcessed,
    setSourceProcessed,
    setTimeProcessed,
    processedCount,
    statsError,
  } = useAppContext();

  // const source = [
  //   {
  //     label: "All",
  //     value: "all",
  //   },

  //   {
  //     label: "TMC",
  //     value: "tmc",
  //   },
  //   {
  //     label: "Stellantis",
  //     value: "stellantis",
  //   },
  // ];
  const time = [
    {
      label: "Last 7 Days",
      value: "last 7 days",
    },

    {
      label: "Last 30 days",
      value: "last 30 days",
    },
    {
      label: "Last Year",
      value: "last year",
    },
    {
      label: "Current Year",
      value: "current year",
    },
  ];

  // const handleChangeSource = (event) => {
  //   setSourceProcesed(event.target.value);
  //   // console.log("hi", value);
  // };
  // const handleChangeTime = (event) => {
  //   setTimeProcessed(event.target.value);
  //   // console.log("hi", value);
  // };
  // useEffect(() => {
  //   fetchBatteryProcessedCount();
  // }, []);
  // useEffect(() => {
  //   console.log("reportData", processedCount);
  // }, [processedCount]);

  // if (statsError) {
  //   return (
  //     <Grid
  //       sx={{
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Typography variant="h2">
  //         Error : {statsError ? statsError : "Something Went Wrong"}
  //       </Typography>
  //     </Grid>
  //   );
  // }
  return (
    <Container maxWidth="false" disableGutters sx={styles.parentContainer}>
      <Stack spacing={4}>
        <Typography variant="h6" color="white">
          Number Of Batteries Processed
        </Typography>
        <Stack direction="row" spacing={4} width={"635px"}>
          {/* <BatteryDropDown
            inputLabel={"Source"}
            source={source}
            // handleChange={handleChangeSource}
            processedData={sourceProcessed}
            setProcessedData={setSourceProcessed}
          /> */}
          <MultiSelect
            sources={sources}
            sourceProcessingStatistics={sourceProcessed}
            setSourceProcessingStatistics={setSourceProcessed}
            isLightTheme={true}
            heading="Source"
          />
          <BatteryDropDown
            inputLabel={"Time"}
            source={time}
            // handleChange={handleChangeTime}
            processedData={timeProcessed}
            setProcessedData={setTimeProcessed}
          />
        </Stack>
        {statsError ? (
          <Grid
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">
              Error : {statsError ? statsError : "Something Went Wrong"}
            </Typography>
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <BatteryStats
              dataToDisplay={"Batteries Added"}
              processedCount={processedCount.added}
            />
            <BatteryStats
              dataToDisplay={"Batteries Updated"}
              processedCount={processedCount.updated}
            />
            <BatteryStats
              dataToDisplay={"Batteries Deleted"}
              processedCount="0"
            />
            <BatteryStats
              dataToDisplay={"Batteries in Database till Date"}
              processedCount={processedCount.total}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

export default NBPStats;
