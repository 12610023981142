import { Grid } from "@mui/material";
import { BatteryStatus } from "../../components/organisms/BatteryStatus/BatteryStatus";
import { styles } from "./styles";
export const BatteryStatusReport = () => {
  return (
    <Grid sx={styles.batteryStatusReport}>
      <BatteryStatus />
    </Grid>
  );
};
