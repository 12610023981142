export const styles = {
  dialogBox: {
    "& .MuiPaper-root": {
      minHeight: "90vh",
    },
    vinLabel: {
      textTransform: "capitilize",
    },
    findLabel: {
      textTransform: "capitalize",
    },
    vehicleLabel: {
      textTransform: "capitalize",
    },
    vinLabels: {
      color: "red",
    },
    exTyp: {
      fontFamily: "ToyotaType-BoldIt",
    },
  },
};
