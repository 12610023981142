import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { sources, useAppContext } from "../../../context/AppContext";
import MultiSelect from "../../atoms/MultiSelect/MultiSelect";
import Pending from "./Pending.png";
// import { REACT_APP_base_url } from "../../../env";
// import styled from "@mui/material";

// const StyledTableRow = styled((theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: "white",
//     },
//     "&:nth-of-type(even)": {
//       backgroundColor: "grey",
//     },
//   },
// }))(TableRow);
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eeeeee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BatteryStatus = () => {
  const { REACT_APP_base_url } = process.env;

  const {
    reportData,
    fetchReportData,
    selectedFilters,
    setSelectedFilters,
    page,
    setPage,
    loading,
    tableDataError,
  } = useAppContext();
  // const { filterReportData, fetchFilterReportData } = useFilterReportContext();
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [loader, setLoader] = useState(true);

  // const downloadFailedReport = async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `${BASEURL}/battery-service-batch/v1/jobs/${id}/errorReport`,
  //       {
  //         responseType: "blob",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("idToken")}`,
  //         },
  //       }
  //     );
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "data.csv");
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //     window.URL.revokeObjectURL(url);

  //     // const blob = new Blob([response.data], { type: "text/csv" });
  //     // saveAs(response.data, "data.csv");
  //   } catch (error) {}
  // };

  // const downloadFailedReport = async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `${BASEURL}/battery-service-batch/v1/jobs/${id}/errorReport`,
  //       {
  //         responseType: "blob",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("idToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       // Try to extract filename from the URL
  //       const urlParts = response.config.url.split("/");
  //       let filename = urlParts[urlParts.length - 1] || "data.csv";

  //       // Ensure filename has .csv extension
  //       if (!filename.toLowerCase().endsWith(".csv")) {
  //         filename += ".csv";
  //       }

  //       // Create Blob URL and download link
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", filename);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     } else {
  //       console.error(
  //         "Failed to download report. Server returned status:",
  //         response.status
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error downloading failed report:", error);
  //   }
  // };
  // const downloadFailedReport = async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `${BASEURL}/battery-service-batch/v1/jobs/${id}/errorReport`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("idToken")}`,
  //           "Content-Type": "text/csv",
  //         },
  //         responseType: "blob", // Set responseType to 'blob' to receive binary data
  //       }
  //     );

  //     const contentDisposition = response.headers["content-disposition"];
  //     let filename = "report.csv"; // Default filename

  //     if (contentDisposition) {
  //       const regex = /filename="(.+)"/;
  //       const match = contentDisposition.match(regex);
  //       if (match) {
  //         filename = match[1];
  //       }
  //     }

  //     // Create Blob URL and download link
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", filename);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading failed report:", error);
  //   }
  // };
  const downloadFailedReport = async (id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_base_url}/battery-service-batch/v1/jobs/${id}/errorReport`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
            "Content-Type": "text/csv",
          },
          responseType: "blob", // Set responseType to 'blob' to receive binary data
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      let filename = "report.csv"; // Default filename

      if (contentDisposition) {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const match = regex.exec(contentDisposition);
        if (match !== null && match[1]) {
          filename = match[1].replace(/['"]/g, "").trim();
        } else {
          console.warn("Filename not found in Content-Disposition header.");
        }
      } else {
        console.warn("Content-Disposition header not found.");
      }

      // Create Blob URL and initiate file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading failed report:", error);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchReportData();
    setLoader(false);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };
  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  // const handleFiltersChange = (event) => {
  //   setSelectedFilters({
  //     ...selectedFilters,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  const handleFiltersChange = (event) => {
    const { name, value } = event.target;
    if (name === "endDate") {
      const endDate = new Date(value);
      const startDate = new Date(selectedFilters.startDate);

      // If end date is before start date, update start date to match end date
      if (endDate < startDate) {
        setSelectedFilters({
          ...selectedFilters,
          startDate: value,
          [name]: value,
        });
        return; // Exit function to prevent updating state again below
      }
    }

    // If the changed field is the "Start Date" field
    if (name === "startDate") {
      const startDate = new Date(value);
      const endDate = new Date(selectedFilters.endDate);

      // If start date is after end date, update end date to match start date
      if (startDate > endDate) {
        setSelectedFilters({
          ...selectedFilters,
          endDate: value,
          [name]: value,
        });
        return; // Exit function to prevent updating state again below
      }
    }
    // Prevent setting future dates
    const selectedDate = new Date(value);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      return; // Do not update state if future date selected
    }

    setSelectedFilters({
      ...selectedFilters,
      [name]: value,
    });
  };
  const handleResetFilter = () => {
    setSelectedFilters({
      source: [],
      status: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleApplyFilter = () => {
    if (selectedFilters) {
      fetchReportData();
      setPage(0);
    } else {
    }
    handleFilterDialogClose();
  };

  const generateEmptyRows = (tableDataError) => {
    let itemCount = 0;
    let isLastPage =
      page + 1 == Math.ceil(reportData?.totalElements / rowsPerPage);
    if (!reportData?.totalElements) {
      itemCount = rowsPerPage;
      isLastPage = true;
    } else {
      itemCount = rowsPerPage - (reportData?.totalElements % rowsPerPage);
    }

    if (!isLastPage) return;
    return Array.from({ length: itemCount }, (_, index) => (
      <TableRow
        key={`empty-row-${index}`}
        style={{
          backgroundColor: "#fff",
          height: "75px",
        }}
      >
        <TableCell colSpan={11}>
          {tableDataError && index === 0 ? (
            <Grid
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">
                Error :{" "}
                {tableDataError ? tableDataError : "Something Went Wrong"}
              </Typography>
            </Grid>
          ) : (
            <>&nbsp;</>
          )}
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <Grid
      container
      sx={{
        height: "100%",
        overflow: "auto",
        // border: "1px solid white",
      }}
    >
      <Grid
        container
        direction="column"
        // gap={"8px"}
        // padding={"8px 26px 0px 26px"}
        sx={{
          position: "relative",
          // height: "100%",
          // border: "1px solid white",
          padding: "0 1.625rem 0 0",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          // paddingLeft: "-3rem",
        }}
      >
        <Grid item>
          <Typography style={{ color: "white", fontSize: "1.5rem" }}>
            Upload Status Report
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Tooltip title={<h2>Filter</h2>}>
                <IconButton onClick={handleFilterDialogOpen}>
                  <TuneIcon style={{ color: "#eb0a1e", fontSize: "3rem" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid sx={{ display: "flex", gap: "2rem" }}>
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                  <CheckIcon style={{ color: "#1aff1a", fontSize: "3rem" }} />
                  <Typography style={{ color: "white" }}>Success</Typography>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                  <ErrorOutlineIcon
                    style={{ color: "#eb0a1e", fontSize: "3rem" }}
                  />
                  <Typography style={{ color: "white" }}>Failed</Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <IconButton
                    style={{
                      color: "#ffff4d",
                      fontSize: "3rem",
                      cursor: "default",
                    }}
                  >
                    <img src={Pending} alt="" />
                  </IconButton>
                  <Typography style={{ color: "white" }}>Pending</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Table
              sx={{
                flexGrow: 1,
                overflowY: "scroll",
                marginTop: "0.75rem",
                paddingRight: "24px",
                marginBottom: "24px",
                minHeight: "95%",
                // border: "1px solid white",
              }}
            >
              <TableHead style={{ backgroundColor: "#eb0a1e" }}>
                <TableRow>
                  <StyledTableCell style={{ color: "white" }}>
                    Data Source
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    File Name
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Date & Time of File Processing
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Status
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Records Recieved
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    New Records
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Updated Records
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    No Change
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Records Failed to Upload
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Download Report
                  </StyledTableCell>
                  <StyledTableCell style={{ color: "white" }}>
                    Remarks
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  backgroundColor: "#f5f5f0",
                  flexGrow: 1,
                  overflowY: "scroll",
                  // marginTop: "0.75rem",
                  // paddingRight: "24px",
                  // marginBottom: "24px",
                }}
              >
                {reportData?.content?.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    // style={{
                    //   backgroundColor: "#fff",
                    //   height: "75px",
                    // }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.source}
                    </StyledTableCell>

                    <StyledTableCell>{row.fileName}</StyledTableCell>
                    <StyledTableCell>
                      {row.endTime?.replace("T", " ")}
                    </StyledTableCell>

                    {row.batchStatus == "FINISHED" && (
                      <StyledTableCell>
                        <CheckIcon style={{ color: "green" }} />
                      </StyledTableCell>
                    )}

                    {row.batchStatus == "FAILED" && (
                      <StyledTableCell>
                        {" "}
                        <ErrorOutlineIcon style={{ color: "red" }} />
                      </StyledTableCell>
                    )}

                    {row.batchStatus == "IN_PROGRESS" && (
                      <StyledTableCell>
                        <img src={Pending} alt="" style={{ color: "yellow" }} />
                      </StyledTableCell>
                    )}

                    <StyledTableCell>
                      {row.totalNumberOfBatteryRecords === 0 &&
                      row.batchStatus == "IN_PROGRESS"
                        ? ""
                        : row.totalNumberOfBatteryRecords}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "lightgrey",
                      }}
                    >
                      {row.totalNumberOfBatteryInserted === 0 &&
                      row.batchStatus == "IN_PROGRESS"
                        ? ""
                        : row.totalNumberOfBatteryInserted}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "lightgrey",
                      }}
                    >
                      {row.totalNumberOfBatteryUpdated === 0 &&
                      row.batchStatus == "IN_PROGRESS"
                        ? ""
                        : row.totalNumberOfBatteryUpdated}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "lightgrey",
                      }}
                    >
                      {row.totalNumberOfBatteryNoChange === 0 &&
                      row.batchStatus == "IN_PROGRESS"
                        ? ""
                        : row.totalNumberOfBatteryNoChange}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "lightgrey",
                      }}
                    >
                      {row.totalNumberOfBatteryFailed === 0 &&
                      row.batchStatus == "IN_PROGRESS"
                        ? ""
                        : row.totalNumberOfBatteryFailed}
                    </StyledTableCell>

                    <StyledTableCell>
                      {row.reportFileName !== null && (
                        <IconButton
                          onClick={() => downloadFailedReport(row.id)}
                        >
                          <FileDownloadIcon style={{ color: "black" }} />
                        </IconButton>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{row?.remark}</StyledTableCell>
                  </StyledTableRow>
                ))}
                {!loading && generateEmptyRows(tableDataError)}
                {loading && (
                  <tr>
                    <td
                      colSpan={11}
                      style={{
                        height: "450px",
                        background: "#fff",
                        textAlign: "center",
                      }}
                    >
                      <CircularProgress sx={{ color: "#eb0a1e" }} />
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
            {/* <Pagination /> */}
            <TablePagination
              component={"div"}
              // alignItems="center"
              count={reportData?.totalElements ? reportData?.totalElements : 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ color: "white", root: { zIndex: 2 } }}
              rowsPerPageOptions={[7]}
            ></TablePagination>
          </div>
        </Grid>
        <Dialog
          open={openFilterDialog}
          onClose={handleFilterDialogClose}
          // sx={{ minWidth: "50rem" }}
        >
          {/* <DialogTitle>Filter Table</DialogTitle> */}
          <DialogContent>
            <Grid
              sx={{ display: "flex", flexDirection: "column", gap: "5rem" }}
            >
              <Grid sx={{ display: "flex", gap: "2rem" }}>
                <MultiSelect
                  sources={sources}
                  sourceProcessingStatistics={selectedFilters?.source}
                  setSourceProcessingStatistics={(e) =>
                    setSelectedFilters({ ...selectedFilters, source: e })
                  }
                  isLightTheme={false}
                  heading="Source"
                />

                {/* <FormControl>
                  <MultiSelect
                    sources={sources}
                    sourceProcessingStatistics={sourceStatusReport}
                    setSourceProcessingStatistics={setSourceStatusReport}
                    isLightTheme={false}
                    heading="Source"
                  />
                </FormControl> */}
                {/* <MultiSelect
                  sources={sources}
                  // sourceProcessingStatistics={sourceFilter}
                  // setSourceProcessingStatistics={setSourceFilter}
                  sourceProcessingStatistics={selectedFilters.source}
                  setSourceProcessingStatistics={(e) => {
                    handleFiltersChange({
                      target: { name: "source", value: e.map((_)=>_.source) },
                    });
                  }}
                /> */}

                <FormControl sx={{ minWidth: "10rem" }}>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    value={selectedFilters.status}
                    onChange={handleFiltersChange}
                    label="status"
                    name="status"
                  >
                    <MenuItem value="FINISHED">Success</MenuItem>
                    <MenuItem value="FAILED">Failed</MenuItem>
                    <MenuItem value="IN_PROGRESS">Pending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", gap: "2rem" }}>
                <TextField
                  id="start-date"
                  label="Start Date"
                  type="datetime-local"
                  value={selectedFilters.startDate}
                  onChange={handleFiltersChange}
                  InputProps={{
                    shrink: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  name="startDate"
                ></TextField>
                <TextField
                  id="end-date"
                  label="End Date"
                  type="datetime-local"
                  value={selectedFilters.endDate}
                  onChange={handleFiltersChange}
                  InputProps={{
                    shrink: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  name="endDate"
                ></TextField>
              </Grid>
              <Grid sx={{ display: "flex", gap: "2rem" }}>
                <Button onClick={handleResetFilter}>Reset</Button>
                <Button onClick={handleApplyFilter}>Apply</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Backdrop open={openFilterDialog} onClick={handleFilterDialogClose} />
      </Grid>
    </Grid>
  );
};
