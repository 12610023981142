import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//This is the structure of data for both users & usersOptions

const MultiSelect = (props) => {
  const {
    setSourceProcessingStatistics,
    sources,
    sourceProcessingStatistics,
    isLightTheme,
    heading,
  } = props;
  //   const [selectedUsers, setSelectedUsers] = useState([]);

  const [usersOptions, setUsersOptions] = useState([]);

  useEffect(() => {
    setUsersOptions(sources);
  }, [sources]);

  return (
    <Autocomplete
      multiple
      // sx={{ width: "50%" }}
      sx={{
        width: "300px",

        "& .MuiAutocomplete-inputRoot": {
          color: isLightTheme ? "white" : "black", // Text color
          borderColor: isLightTheme ? "white" : "black",
        },
        "& .MuiAutocomplete-endAdornment": {
          color: isLightTheme ? "white" : "black", // Checkbox color
        },
        "& .MuiCheckbox-colorPrimary.Mui-checked": {
          color: isLightTheme ? "white" : "black", // Checked checkbox color
        },
        "& .MuiSvgIcon-root": {
          fill: isLightTheme ? "white" : "black", // Icon color
        },
        "& .MuiInputLabel-root": {
          color: isLightTheme ? "white" : "black", // Label color
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: isLightTheme ? "white" : "black", // Outline border color
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: isLightTheme ? "white" : "black", // Focused outline border color
        },
        "&:focus": {
          backgroundColor: isLightTheme ? "white" : "black", // Focused background color
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isLightTheme ? "white" : "black", // Hovered outline border color
          },
        },
      }}
      limitTags={1}
      disableCloseOnSelect
      id="tags-outlined"
      options={usersOptions}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={
              option.value === 0
                ? sourceProcessingStatistics.length === usersOptions.length
                : selected
            }
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={heading} placeholder="" />
      )}
      value={sourceProcessingStatistics}
      onChange={(event, newValue) => {
        // console.log("newval", newValue);
        // Check if 'All' option is clicked
        if (newValue.find((option) => option.value === 0)) {
          // Check if all options are selected
          if (usersOptions.length === sourceProcessingStatistics.length) {
            setSourceProcessingStatistics([]);
          } else {
            setSourceProcessingStatistics(usersOptions);
          }
        } else {
          setSourceProcessingStatistics(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);
        let optionName =
          usersOptions.length === sourceProcessingStatistics.length
            ? "All"
            : "All";
        return [{ value: 0, label: optionName }, ...filtered];
      }}
    />
  );
};

export default MultiSelect;
