import { sources, useAppContext } from "../../../context/AppContext";
import MultiSelect from "../MultiSelect/MultiSelect";

// const StyleFormControl = styled(FormControl)({
//   "& label": {
//     color: "black",
//   },
//   "& .MuiInput-underline": {
//     borderBottomColor: "black",
//   },

//   "& label.Mui-focused": {
//     color: "black",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "black",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "black",
//     },
//     "&:hover fieldset": {
//       borderColor: "black",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "black",
//     },
//   },
// });

export const SourceDropDown = () => {
  const { sourceProcessingStatistics, setSourceProcessingStatistics } =
    useAppContext();
  return (
    <MultiSelect
      sources={sources}
      sourceProcessingStatistics={sourceProcessingStatistics}
      setSourceProcessingStatistics={setSourceProcessingStatistics}
      heading="Source"
    />
  );
};

//   const [value, setValue] = useState([]);

//   return (
//     <MultiSelect
//       items={sources}
//       // getOptionDisabled={getOptionDisabled}
//       label="Select complex values"
//       placeholder="Placeholder for textbox"
//       selectAllLabel="Select all"
//       onChange={setValue}
//       style={{ width: "220px" }}
//     />
//   );
// };
