import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { FindVin } from "../../atoms/FindVin/FindVin";
import { InputField } from "../../atoms/InputField/InputField";
import { SearchButton } from "../../atoms/SearchButton/SearchButton";
import { ToyotaParagraph } from "../../atoms/ToyotaParagraph/ToyotaParagraph";
import { WelcomeHeading } from "../../atoms/WelcomeHeading/WelcomeHeading";
import { DialogPop } from "../../molecules/Dialog/DialogPop";

export const Content = () => {
  const {
    vinNumber,
    setBinNumber,
    binNumber,
    setVinNumber,
    setBinError,
    authError,
    binError,
  } = useAppContext();
  const [validVin, setValidVin] = useState(true);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    setBinError("");
  }, []);

  useEffect(() => {
    if (authError) {
      setOpenSnackbar(true);
    }
  }, [authError]);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        height: "100%",
        px: "26px",
      }}
    >
      <Grid
        container
        item
        sx={(theme) => ({
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
          // height: "33%",
          gap: "8px",
          [theme.breakpoints.down("sm")]: {
            // height: "40%",
          },
        })}
      >
        <Grid item>
          <WelcomeHeading />
        </Grid>
        <Grid item>
          <ToyotaParagraph />
        </Grid>
      </Grid>
      <Grid
        container
        sx={(theme) => ({
          // height: "33%",
          justifyContent: "center",
          flexGrow: 2,
          [theme.breakpoints.down("sm")]: {
            // height: "40%",
          },
        })}
        // border={"1px solid white"}
      >
        <Grid item>
          <Grid container direction={"row"}>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems={"flex-end"}
                gap={1}
                // border={"1px solid white"}
              >
                <Grid
                  container
                  item
                  sx={{
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Grid>
                    <InputField
                      vinNumber={vinNumber}
                      setVinNumber={setVinNumber}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <SearchButton
                    binNumber={binNumber}
                    vinNumber={vinNumber}
                    setValidVin={setValidVin}
                    validVin={validVin}
                    setBinNumber={setBinNumber}
                  />
                </Grid>
                <Grid item>
                  <FindVin handleOpen={handleOpen} />
                  <DialogPop open={open} setOpen={setOpen} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", marginTop: "1rem" }}>
            <Grid item sx={{ minHeight: "50px" }}>
              {binError && (
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: "269px",
                    fontSize: "1.05rem",
                    color: "red",
                    display: "block", // Always block to reserve space
                    textShadow: "2px 2px 2px black",
                    // backgroundColor: "lightgrey",
                  }}
                >
                  {binError}
                </Typography>
              )}
              {!validVin && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",

                    fontSize: "1.15rem",
                    visibility: "visible", // Show only if validVin is false
                    textShadow: "2px 2px 2px black",
                  }}
                >
                  Invalid VIN Format
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          onClose={handleClose}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {authError ? authError : "Something went wrong, please try again...!"}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
