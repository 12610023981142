export const styles = {
  grid: {
    height: "100%",
    overflow: "auto",
  },
  paper: {
    position: "relative",
    height: "100%",
    padding: "1rem 1.625rem",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  gridCard: {
    padding: "1rem 0rem ",
    rowGap: "0.75rem",
  },
  gridMarking: { columnGap: "15rem", paddingBottom: "1rem" },
  markingDivider: {
    width: "1.25px",
    height: "7rem",
    background: "red",
    margin: "0",
  },
  gridDocuments: { gap: "15rem", paddingBottom: "1rem" },
  declaration: { paddingLeft: "2rem", color: "red" },
  seperator: {
    flexGrow: 1,
    backgroundColor: "red",
    height: ".75px",
    border: "0px",
  },
};
