import { Typography, Grid } from "@mui/material";

export const UpdateTime = (props) => {
  const { image, label, value, width, sx = {} } = props;
  return (
    <Grid
      sm={width}
      sx={{
        display: "flex",
        gap: "1rem",
        padding: "0 1rem",
        ...sx,
      }}
    >
      <Typography sx={{ alignContent: "center" }}>
        <img src={image} alt="time"></img>
      </Typography>
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ color: "#6ac3fd", fontWeight: "bold" }}>
          {label}:
        </Typography>
        <Typography sx={{ color: "white" }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};
