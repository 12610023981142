import { Typography } from "@mui/material";
import { styles } from "./styles";
import { BatteryPassportConst } from "../../../assets/consts/consts";
export const BatteryPassport = () => {
  return (
    <Typography variant="h3" sx={styles.batterypassport}>
      {BatteryPassportConst}
    </Typography>
  );
};
