import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MarkingImage from "./markingimage1.jpg";
import { styles } from "./styles";
// import { fetchVinDetails } from "../../../fetchVinDetails";
import axios from "axios";
import { useAppContext } from "../../../context/AppContext";

import HistoryIcon from "@mui/icons-material/History";
import { AuditTrailDialog } from "../AuditTrailDialog/AuditTrailDialog";
// import { REACT_APP_base_url } from "../../../env";

export const BinDetails = (props) => {
  const { REACT_APP_base_url } = process.env;

  const [loadings, setLoadings] = useState(false);
  const { isAuthenticated } = props;
  const {
    binNumber,
    setBinNumber,
    binDetails,
    fetchBinDetails,
    binError,
    fetchAuditTrail,
    fetchAdminBinDetails,
    loading,
  } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const { paramBinNumber } = useParams();

  useEffect(() => {
    if (!binNumber) {
      setBinNumber(paramBinNumber);
    }
  }, [binNumber]);

  const handleOpen = async () => {
    await fetchAuditTrail(paramBinNumber);
    setOpenDialog(true);
  };

  // useEffect(() => {
  //   console.log("seconddddddddddddd");

  //   const fetchBinDetail = async () => {
  //     setLoading(true);
  //     try {
  //       await fetchBinDetails();
  //       setLoading(false);
  //     } catch (error) {
  //       if (error.response && error.response.status === 404) {
  //         setError(
  //           "Battery Information related to the selected VIN is not available"
  //         );
  //       } else {
  //         setError(error);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (binNumber) {
  //     console.log("third");

  //     fetchBinDetail();
  //   }
  // }, [binNumber]);

  useEffect(() => {
    const tempFn = async () => {
      if (Object.keys(binDetails)?.length === 0) {
        setLoadings(() => true);
        if (isAuthenticated) {
          await fetchAdminBinDetails(paramBinNumber);
          setLoadings(() => false);
        } else {
          await fetchBinDetails(paramBinNumber);
          setLoadings(() => false);
        }
      }
    };
    tempFn();
  }, [binDetails, paramBinNumber]);

  if (loading) {
    return (
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  // if (loadings) {
  //   console.log("hello");

  //   return (
  //     <Grid
  //       sx={{
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Grid>
  //   );
  // }
  // if (binDetails == null) {
  //   return (
  //     <Grid
  //       sx={{
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Typography variant="h2">
  //         Error :
  //         {error?.message
  //           ? error.message
  //           : "Battery Information related to the selected VIN is not available"}
  //       </Typography>
  //     </Grid>
  //   );
  // }
  if (binError) {
    return (
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">
          Error : {binError ? binError : "Something Went Wrong"}
        </Typography>
      </Grid>
    );
  }

  const KeyValue = ({ label, value, link, tooltip, valueTooltip }) => {
    const TooltipWrapper = ({ title, children }) => {
      return title ? <Tooltip title={title}>{children}</Tooltip> : children;
    };
    return (
      <Grid
        sx={{
          display: "flex",
          width: "100%",
          gap: "8px",
          alignItems: "flex-start",
          wordBreak: "break-word",
          marginBottom: "8px",
        }}
      >
        <TooltipWrapper title={tooltip}>
          <Typography
            sx={{
              minWidth: "45%",
              maxWidth: "45%",
            }}
          >
            {label}
          </Typography>
        </TooltipWrapper>

        <Typography>:</Typography>
        <TooltipWrapper title={valueTooltip}>
          <Typography sx={{ flexGrow: 1, fontWeight: "bold" }}>
            {link ? (
              <a href={link} target="_blank" rel="noreferrer">
                {value}
              </a>
            ) : (
              value
            )}
          </Typography>
        </TooltipWrapper>
      </Grid>
    );
  };

  // const role = "admin";
  // const SelectedLayout = ({ children }) => {
  //   if (role == "admin") {
  //     return (
  //       <div>
  //         <h1>asjkda</h1>
  //         {children}
  //       </div>
  //     );
  //   } else {
  //     return <BaseLayout>{children}</BaseLayout>;
  //   }
  // };
  // const downloadPDF = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.dbp-dev.toyota-europe.com/battery-service/public/v1/batteries/document?file=${binDetails.DocFileName}`,
  //       {
  //         responseType: "blob", // Specify blob response for binary data
  //       }
  //     );

  //     const pdfUrl = URL.createObjectURL(
  //       new Blob([response.data], { type: "application/pdf" })
  //     );
  //     const fileName = response.filename || "DocumentofConformity.pdf";
  //     const newWindow = window.open(pdfUrl, "_blank", "noopener,noreferrer");

  //     if (newWindow) newWindow.focus();
  //   } catch (error) {
  //     console.error("Error downloading PDF:", error);
  //   } finally {
  //   }
  // };

  // const downloadCSV = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.dbp-dev.toyota-europe.com/battery-service/public/v1/batteries/document?file=${binDetails.DocFileName}`,
  //       {
  //         responseType: "arraybuffer", // Specify arraybuffer response for binary data
  //       }
  //     );

  //     const csvBlob = new Blob([response.data], { type: "text/csv" });

  //     // Extract filename from response headers
  //     const contentDisposition = response.headers["content-disposition"];
  //     const filenameMatch = contentDisposition.match(/filename="(.+)"/);
  //     let filename = "document.csv"; // Default filename if not found in headers
  //     if (filenameMatch && filenameMatch.length > 1) {
  //       filename = filenameMatch[1];
  //     }

  //     // Create temporary anchor element
  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = window.URL.createObjectURL(csvBlob);
  //     downloadLink.setAttribute("download", filename);

  //     // Append the anchor to the body and click it to trigger download
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();

  //     // Cleanup
  //     document.body.removeChild(downloadLink);
  //   } catch (error) {
  //     console.error("Error downloading CSV:", error);
  //   }
  // };
  const openPDFInNewTab = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_base_url}/battery-service/public/v1/batteries/document?file=${binDetails.DocFileName}`,
        {
          responseType: "arraybuffer", // Specify arraybuffer response for binary data
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Open PDF in new browser tab
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error opening PDF in new tab:", error);
    }
  };

  return (
    <Grid sx={styles.grid}>
      {/* <SelectedLayout> */}
      <Paper sx={styles.paper}>
        <Grid sx={{ display: "flex", gap: "1rem" }}>
          <Typography
            variant="h1"
            sx={(theme) => ({
              fontSize: "24px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
              },
            })}
          >
            <Tooltip title="A battery Identification number, more commonly referred to as a BIN, is a 24 character code that is unique to a Toyota/Lexus traction battery">
              <span>BIN</span>
            </Tooltip>
            : <span style={{ color: "black" }}>{binDetails.BIN}</span>
          </Typography>
          {isAuthenticated && (
            <Typography>
              <Tooltip title="Audit Trail">
                <Link
                  component={"button"}
                  onClick={handleOpen}
                  underline="hover"
                  style={{ color: "red" }}
                >
                  <HistoryIcon style={{ fontSize: "2.25rem" }} />
                </Link>
              </Tooltip>
              <AuditTrailDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                binNo={binDetails.BIN}
              />
            </Typography>
          )}
        </Grid>
        <Grid
          // direction={"column"}
          // style={{ flexDirection: "column" }}
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            marginTop: "0.75rem",
            paddingRight: "24px",
            marginBottom: "24px",
          }}
        >
          <Grid container sx={styles.gridCard}>
            <Grid container alignItems={"center"} gap={"1rem"}>
              <Typography variant="h2">Manufacturing Details</Typography>
              <hr style={styles.seperator} />
            </Grid>
            <Grid container spacing={2} sx={{ paddingBottom: "1rem" }}>
              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <KeyValue
                  label="Manufacturer Name"
                  value={binDetails.BatteryManufacturerName}
                />
                <KeyValue
                  label="Registered Trade Name"
                  value={binDetails.RegisteredTradeName}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}></Grid> */}
              <Grid item xs={12} sm={4}>
                <KeyValue label="Address" value={binDetails.PostalAddress} />

                <KeyValue
                  label="Contact"
                  value={binDetails.Contact}
                  link={`mailto:${binDetails.Contact}`}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={styles.gridCard}>
            <Grid container alignItems={"center"} gap={"1rem"}>
              <Typography variant="h2">Battery General Information</Typography>
              <hr style={styles.seperator} />
            </Grid>

            <Grid container spacing={2} sx={{ paddingBottom: "1rem" }}>
              <Grid item xs={12} sm={4}>
                <KeyValue
                  label="Battery Category"
                  value={"Electric Vehicle Battery"}
                  valueTooltip="Battery that is specifically designed to provide electric power for traction in hybrid or electric vehicles"
                />

                <KeyValue
                  label="Place of Manufacture"
                  value={binDetails.PlaceOfManufacture}
                />

                <KeyValue
                  label="Manufacturing Date"
                  value={binDetails.DateOfManufacture}
                />
                <KeyValue
                  label="Usable Extinguishing Agent"
                  value={binDetails.UsableOfExtinguishingAgent}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyValue
                  label="Cell Resistance"
                  value={
                    binDetails.CellResistance &&
                    `${binDetails.CellResistance} mΩ`
                  }
                />
                <KeyValue
                  label="Pack Resistance"
                  value={
                    binDetails.PackResistance &&
                    `${binDetails.PackResistance} Ω`
                  }
                  // value={`${binDetails.PackResistance} Ω`}
                />
                <KeyValue label="Chemistry" value={binDetails.Chemistry} />
                <KeyValue
                  label="Initial round trip energy efficiency"
                  value={
                    binDetails.InitialRoundTripEnergyEfficiency &&
                    `${binDetails.InitialRoundTripEnergyEfficiency} %`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <KeyValue
                    label="Voltage values for a temperature range"
                    value={`${binDetails.LowestTemperatureForVoltage}°C to 100°C`}
                  /> */}
                <Typography>
                  Voltage values for a temperature range between{" "}
                  {binDetails.LowestTemperatureForVoltage}
                  °C to {binDetails.HighestTemperatureForVoltage}°C
                </Typography>
                <br />
                <Grid>
                  <KeyValue
                    label=" - Minimal Voltage"
                    value={
                      binDetails.MinimalVoltage &&
                      `${binDetails.MinimalVoltage} V`
                    }
                  />
                  <KeyValue
                    label=" - Nominal Voltage"
                    value={
                      binDetails.NominalVoltage &&
                      `${binDetails.NominalVoltage} V`
                    }
                  />
                  <KeyValue
                    label=" - Maximum Voltage"
                    value={
                      binDetails.MaximumVoltage &&
                      `${binDetails.MaximumVoltage} V`
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={styles.gridCard}>
            <Grid container alignItems={"center"} gap={"1rem"}>
              <Typography variant="h2">
                Performance & Durability Parameters
              </Typography>
              <hr style={styles.seperator} />
            </Grid>

            <Grid container spacing={2} sx={{ paddingBottom: "1rem" }}>
              <Grid item xs={12} sm={4}>
                <KeyValue
                  label="Rated Capacity"
                  value={
                    binDetails.RatedCapacity && `${binDetails.RatedCapacity} Ah`
                  }
                />
                <KeyValue
                  label="Capacity Fade"
                  value={
                    binDetails.CapacityFade && `${binDetails.CapacityFade} %`
                  }
                />
                <KeyValue
                  label="Power"
                  value={binDetails.Power && `${binDetails.Power} W`}
                />
                <KeyValue
                  label="Power Fade"
                  value={binDetails.PowerFade && `${binDetails.PowerFade} %`}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyValue
                  label="Internal Resistance"
                  value={
                    binDetails.InternalResistance &&
                    `${binDetails.InternalResistance} Ω`
                  }
                />
                <KeyValue
                  label="Internal Resistance increase"
                  value={
                    binDetails.InternalResistanceIncrease &&
                    `${binDetails.InternalResistanceIncrease} %`
                  }
                />

                <KeyValue
                  label="No.of charge-discharge cycles"
                  value={
                    binDetails.NumberOfChargeDischargeCycles &&
                    `${binDetails.NumberOfChargeDischargeCycles} cycles`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyValue
                  label="Expected Lifetime (minimum)"
                  value={
                    binDetails.ExpectedLifeTime &&
                    `${binDetails.ExpectedLifeTime} Years`
                  }
                />
                <KeyValue
                  label="Energy round trip efficiency"
                  value={
                    binDetails.EnergyRoundTripEfficiency &&
                    `${binDetails.EnergyRoundTripEfficiency} %`
                  }
                />
                <KeyValue
                  label="Energy round trip efficiency fade"
                  value={
                    binDetails.EnergyRoundTripEfficiencyFade &&
                    `${binDetails.EnergyRoundTripEfficiencyFade} %`
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} gap={"1rem"}>
            <Typography variant="h2">Markings </Typography>
            <hr style={styles.seperator} />
          </Grid>

          <Grid
            container
            direction={"row"}
            justifyContent={"space-evenly"}
            paddingTop={"2rem"}
          >
            <Grid>
              <Tooltip
                title="The battery should not be discarded as unsorted waste 
                  but must be sent to separate collection facilities
                  for recovery and recycling"
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={MarkingImage}
                  alt="marking"
                  width={96}
                  height={109}
                />
              </Tooltip>
            </Grid>
            <Divider orientation="vertical" sx={styles.markingDivider} />
            <Grid
              sx={{
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {binDetails.CadmiumAbovePoint002percent === "Y" && (
                <Tooltip title="Percentage of Cadmium present on the battery is above 0.002%">
                  <Typography variant="h1" style={{ color: "black" }}>
                    Cd
                  </Typography>
                </Tooltip>
              )}

              {binDetails.LeadAbovePoint004percent === "Y" && (
                <Tooltip title="Percentage of Lead present on the battery is above 0.004%">
                  <Typography variant="h1" style={{ color: "black" }}>
                    Pb
                  </Typography>
                </Tooltip>
              )}
              {/* {binDetails.LeadAbovePoint004percent === "N"} */}
            </Grid>
          </Grid>

          <Grid container sx={styles.gridCard}>
            <Grid container alignItems={"center"} gap={"1rem"}>
              <Typography variant="h2">Documents</Typography>
              <hr style={styles.seperator} />
            </Grid>

            <Grid container spacing={2} sx={styles.gridDocuments}>
              <Grid item>
                <Typography>
                  Declaration of Conformity :
                  <Link style={styles.declaration} onClick={openPDFInNewTab}>
                    Link
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* </SelectedLayout> */}
    </Grid>
  );
};
