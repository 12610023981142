export const styles = {
  batteryDataReport: {
    height: "100%",
    overflow: "auto",
    marginLeft: "5px",
  },
  // batteryStatus:{
  //   container sx={{ position: "relative", height: "100%" }}
  // }
};
