import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import React from "react";

const WhiteSelect = styled(Select)({
  "& .MuiSelect-select, & .MuiInputLabel-root": {
    color: "white", // Text and label color
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Border color
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(255, 255, 255)",
  },
  "& .MuiSvgIcon-root": {
    fill: "white", // Icon color
  },
  "&:focus": {
    backgroundColor: "white", // Focused background color
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white", // Hover border color
    },
  },
});

function BatteryDropDown({
  inputLabel,
  source,
  processedData,
  setProcessedData,
}) {
  const handleChange = (event) => {
    setProcessedData(event.target.value);
  };
  // console.log(sourceDropDownList);

  return (
    <Box sx={{ minWidth: 300 }}>
      <FormControl fullWidth>
        <InputLabel
          style={{ color: "white" }} // Set label color
          // inputProps={{ style: { color: "white" } }} // Set icon color
        >
          {inputLabel}
        </InputLabel>
        <WhiteSelect
          value={processedData}
          label={inputLabel}
          onChange={handleChange}
          variant="outlined"
          inputProps={{ style: { color: "white" } }} // Set text color
        >
          {source.map((source, i) => (
            <MenuItem key={i} value={source.value}>
              {source.label}
            </MenuItem>
          ))}
        </WhiteSelect>
      </FormControl>
    </Box>
  );
}

export default BatteryDropDown;
