import { ThemeProvider } from "@emotion/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { pubClientApp } from "./oidc/MSALAuth/MSALAuth";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./styles/theme";

// pubClientApp.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS) {
//     console.log(event);
//     pubClientApp.setActiveAccount(event.payload.account);
//     window.location.href = "/admin/dashboard";
//   }
// });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App msalInstance={pubClientApp} />
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
