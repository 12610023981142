export const styles = {
  dbpheading2: {
    fontFamily: "ToyotaType-Regular",
    borderTop: "1.5px solid #eb0a1e",
    borderBottom: "1.5px solid #eb0a1e",
    padding: "5px 0px 5px 0px",
    color: "#ffff",
    // height: "20%",
    width: "100%",
    maxWidth: "720px",
  },
};
