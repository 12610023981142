export const styles = {
  listItemText: {
    color: "white",
    display: "block",
    textAlign: "left",
    fontSize: "16px",
    padding: "8px 64px",
    transition: "0.3s",
    fontWeight: 650,
    fontFamily: "ToyotaType-Regular",
  },
  drawerList: {
    height: "100%",
    width: "33.33vw",
    backgroundColor: "#eb0a1e",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  navWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};
