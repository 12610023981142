import { Switch, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";

export const SwitchButton = (props) => {
  const { setChecked } = props;
  const [switchValue, setSwitchValue] = useState(false);

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked);
    setChecked((checked) => !checked);
  };

  // useEffect(() => {
  //   fetchBatteryData();
  //   console.log("switchvalueeeeeee", switchValue);
  // }, [switchValue]);

  return (
    <div>
      <Tooltip title={<div>Data Column selector</div>}>
        <ToggleButtonGroup
          value={switchValue ? "all" : "default"}
          exclusive
          onChange={(event, newValue) => {
            if (newValue === "all") {
              setSwitchValue(true);
            } else if (newValue === "default") {
              setSwitchValue(false);
            }
          }}
          aria-label="Switch content"
        >
          <Typography
            variant="h2"
            sx={{
              color: switchValue ? "white" : "grey",
              cursor: "pointer",
              py: 1,
              px: 2,
              borderRadius: 16,
              // bgcolor: switchValue ? "transparent" : "primary.main",
            }}
            onClick={() => setSwitchValue(false)}
          >
            Default
          </Typography>
          <Switch
            checked={switchValue}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked, & .MuiSwitch-switchBase": {
                color: "red",
              },

              borderRadius: "10px",
              bgcolor: "rgba(255, 255, 255, 0.8)",
            }}
          />
          <Typography
            variant="h2"
            sx={{
              color: switchValue ? "grey" : "white",
              cursor: "pointer",
              py: 1,
              px: 2,
              borderRadius: 16,
              // bgcolor: switchValue ? "primary.main" : "transparent",
            }}
            onClick={() => setSwitchValue(true)}
          >
            All
          </Typography>
        </ToggleButtonGroup>
      </Tooltip>
    </div>
  );
};
