import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiService2 } from "../../../services/ApiService";
import { PopupDataUpload } from "../../atoms/PopupDataUpload/PopupDataUpload";
import { UploadButton } from "../../atoms/UploadButton/UploadButton";
import { DragDrop } from "../../molecules/DragDrop/DragDrop";
import { SourceDoc } from "../../molecules/SourceDoc/SourceDoc";

export const DataUpload = (props) => {
  const [sourceValue, setSourceValue] = useState("");
  const [docValue, setDocValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const [error, setError] = useState({
    sourceValue: false,
    docValue: false,
    selectedFile: false,
  });

  const source = [
    {
      label: "TMC",
      value: "tmc",
    },
    {
      label: "Stellantis",
      value: "stellantis",
    },
  ];
  const documents = [
    {
      label: "Declaration of Conformity",
      value: "doc",
    },
    {
      label: "Privacy & Cookie Policy",
      value: "policy",
    },
    {
      label: "FAQ (Frequently Asked Questions)",
      value: "faq",
    },
  ];

  useEffect(() => {
    setError((old) => ({
      ...old,
      sourceValue: false,
    }));
  }, [sourceValue]);
  useEffect(() => {
    setError((old) => ({
      ...old,
      docValue: false,
    }));
  }, [docValue]);
  useEffect(() => {
    setError((old) => ({
      ...old,
      selectedFile: false,
    }));
  }, [selectedFile]);

  const handleClick = async () => {
    setError({
      sourceValue: !sourceValue,
      docValue: !docValue,
      selectedFile: !selectedFile,
    });

    if (props.type === "pdf") {
      if (docValue === "policy" || docValue === "faq") {
        let formData = new FormData();
        formData.append("file", selectedFile);
        // formData.append("source", sourceValue);
        // formData.append("type", docValue);
        setDisabled(true);

        ApiService2.post(
          "v1/batteries/document",
          formData,
          {
            type: docValue,
          },
          {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          }
        )
          .then((res) => {
            setDisabled(false);
            setUploadSuccess("pass");
            setShowPopup(true);
          })
          .catch((err) => {
            setDisabled(false);
            setUploadSuccess("failed");
            setShowPopup(true);
          });
      } else if (sourceValue && selectedFile && docValue) {
        let formData = new FormData();
        formData.append("file", selectedFile);
        // formData.append("source", sourceValue);
        // formData.append("type", docValue);
        setDisabled(true);

        ApiService2.post(
          "v1/batteries/document",
          formData,
          {
            source: sourceValue,
            type: docValue,
          },
          {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          }
        )
          .then((res) => {
            setDisabled(false);
            setUploadSuccess("pass");
            setShowPopup(true);
          })
          .catch((err) => {
            setDisabled(false);
            setUploadSuccess("failed");
            setShowPopup(true);
          });
      }
    }
    if (props.type === "xlsx") {
      if (sourceValue && selectedFile) {
        let formData = new FormData();
        formData.append("file", selectedFile);
        // formData.append("source", sourceValue);
        setDisabled(true);

        ApiService2.post(
          "v1/batteries/data",
          formData,
          {
            source: sourceValue,
          },
          {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          }
        )
          .then((res) => {
            setDisabled(false);
            setUploadSuccess("pass");
            setShowPopup(true);
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              setUploadSuccess("failedTemplate");
            } else {
              setUploadSuccess("failed");
            }
            setDisabled(false);
            setShowPopup(true);
          });
      }
    }
  };

  const [processedData, setProcessedData] = useState({});
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          borderRadius: "1rem",
          backgroundColor: "#fff",
          height: "100%",
          position: "relative",
          width: "49%",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#0099ff",
            borderRadius: "1rem 1rem 0rem 0rem",
            color: "white",
            paddingY: "1rem",
            paddingX: "1rem",
          }}
        >
          {props.heading}
        </Typography>
        <SourceDoc
          source={source}
          documents={documents}
          type={props.type}
          sourceValue={sourceValue}
          setSourceValue={setSourceValue}
          docValue={docValue}
          setDocValue={setDocValue}
          processedData={processedData}
          setProcessedData={setProcessedData}
          error={error}
        />

        <DragDrop
          type={props.type}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          error={error}
        />
        <UploadButton handleClick={handleClick} disabled={disabled} />
        {props.show}
      </Grid>
      <PopupDataUpload
        open={showPopup}
        setOpen={setShowPopup}
        type={
          uploadSuccess == "pass"
            ? "success"
            : uploadSuccess == "failedTemplate"
            ? "failedTemplate"
            : "failed"
        }
      />
    </>
  );
};
