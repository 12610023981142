import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import { theme } from "../../../styles/theme";

const WhiteSelect = styled(Select)({
  "& .MuiSelect-select, & .MuiInputLabel-root": {
    color: "white", // Text and label color
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Border color
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(255, 255, 255)",
  },
  "& .MuiSvgIcon-root": {
    fill: "white", // Icon color
  },
  "&:focus": {
    backgroundColor: "white", // Focused background color
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white", // Hover border color
    },
  },
});
const CssTextField = styled(TextField)({
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline": {
    borderBottomColor: "#ffff",
  },

  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffff",
    },
  },
});

export const VinSearch = () => {
  const {
    vinNumber,
    setVinNumber,
    fetchAdminBinNumber,
    binError,
    setBinError,
    fetchAdminBinDetails,
  } = useAppContext();
  const [value, setValue] = useState("VIN");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValue(event.target.value);
    setValidBin(true);
    setValidVin(true);
  };
  const [ID, setID] = useState("");
  const [validVin, setValidVin] = useState(true);
  const [validBin, setValidBin] = useState(true);

  const handleChangeVin = (e) => {
    setVinNumber(e.target.value);
  };
  const handleChangeBin = (e) => {
    setID(e.target.value);
  };

  const handleSubmitVin = async () => {
    const isValid = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{17}$/;
    setBinError("");
    if (isValid.test(vinNumber)) {
      setValidVin(true);
      await fetchAdminBinNumber(navigate);
      // navigate(`bin/${props.binNumber}`);
    } else {
      setValidVin(false);
    }
  };

  const handleSubmitBin = async (ID) => {
    // const isValid = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{24}$/;
    const isValid = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9-]{24}$/;
    setBinError("");

    if (isValid.test(ID)) {
      setValidBin(true);
      await fetchAdminBinDetails(ID, navigate);

      // await fetchBinNumber(navigate);
      // navigate(`bin/${props.binNumber}`);
    } else {
      setValidBin(false);
    }
    // navigate("/bin/:ID");
  };
  return (
    <Grid
      //   marginLeft="5rem"
      //   marginTop="4rem"
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="baseline"
      gap="1rem"
    >
      <FormControl sx={{ minWidth: "280px" }} size="medium">
        <WhiteSelect
          id="demo-select-small"
          value={value}
          onChange={handleChange}
        >
          <MenuItem value="VIN">VIN</MenuItem>
          <MenuItem value="BIN">BIN</MenuItem>
        </WhiteSelect>
      </FormControl>
      {value === "VIN" && (
        <CssTextField
          sx={{ width: "400px" }}
          hiddenLabel
          value={vinNumber}
          id="filled-hidden-label-small"
          label="Enter 17 Digits VIN Number"
          variant="outlined"
          onChange={handleChangeVin}
          // error={lengthErrorVIN}
          // helperText={lengthErrorVIN ? "Incorrect VIN format" : ""}
          InputProps={{
            pattern: "[A-Za-z ]+$",
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  endIcon={<SearchIcon />}
                  style={{ color: "#fff" }}
                  onClick={handleSubmitVin}
                >
                  {/* <SearchIcon onClick={()=>alert("clicked")}/> */}
                </Button>
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              color: theme.custom.textFieldColor,
            },
          }}
        />
      )}
      {value === "BIN" && (
        <CssTextField
          sx={{ width: "400px" }}
          hiddenLabel
          id="filled-hidden-label-small"
          label="Enter 24 Digits BIN Number"
          value={ID}
          variant="outlined"
          onChange={handleChangeBin}
          InputProps={{
            pattern: "[A-Za-z ]+",
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  endIcon={<SearchIcon />}
                  style={{ color: "#fff" }}
                  onClick={() => handleSubmitBin(ID)}
                >
                  {/* <SearchIcon onClick={()=>alert("clicked")}/> */}
                </Button>
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              color: theme.custom.textFieldColor,
            },
          }}
        />
      )}
      {!validVin && (
        <Typography
          // style={{
          //   color: "#fff",
          //   color: "red",
          //   fontSize: "16px",
          //   fontFamily: "ToyotaType-Bold",
          // }}
          variant="h2"
        >
          Invalid VIN Format
        </Typography>
      )}
      {!validBin && (
        <Typography
          // style={{
          //   color: "#fff",
          //   color: "red",
          //   fontSize: "16px",
          //   fontFamily: "ToyotaType-Bold",
          // }}
          variant="h2"
        >
          Invalid BIN Format
        </Typography>
      )}
      {binError && <Typography variant="h2"> {binError}</Typography>}
    </Grid>
  );
};
