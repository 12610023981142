import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useAppContext } from "../../../context/AppContext";
import { BaseYear } from "../../../assets/consts/BaseYear";

// const StyleFormControl = styled(FormControl)({
//   "& label": {
//     color: "white",
//   },
//   "& .MuiInput-underline": {
//     borderBottomColor: "#ffff",
//   },

//   "& label.Mui-focused": {
//     color: "white",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "white",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "white",
//     },
//     "&:hover fieldset": {
//       borderColor: "white",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#ffff",
//     },
//   },
// });

export const YearDropDown = () => {
  const { yearProcessingStatistics, setYearProcessingStatistics } =
    useAppContext();

  const handleChange = (event) => {
    setYearProcessingStatistics(event.target.value);
  };
  
  const getYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return Array(currentYear-BaseYear + 1).fill().map((_, i) => BaseYear + i).map((year) => {
      return (
        <MenuItem value={year}>{year}</MenuItem>
      )
    });
  }
  return (
    <FormControl variant="outlined" sx={{ width: "50%" }}>
      <InputLabel id="source-dropdown-label">Year</InputLabel>
      <Select
        labelId="source-dropdown-label"
        id="source-dropdown"
        value={yearProcessingStatistics}
        onChange={handleChange}
        label="Year"
      >{
        getYears()
      }
      </Select>
    </FormControl>
  );
};
