import LexusLogoIcon from "./LEXUS_LOGO_ICON.png";

export const LexusLogo = () => {
  return (
    <img
      style={{ marginLeft: "1.5rem", width: "auto", height: "28px" }}
      src={LexusLogoIcon}
      alt="Lexus_Logo"
    />
  );
};
