import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import UploadDropDown from "../../atoms/UploadDropDown/UploadDropDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { useAppContext,sources } from "../../../context/AppContext";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import MultiSelect from "../../atoms/MultiSelect/MultiSelect";
import { tr } from "date-fns/locale";
import { color } from "highcharts";

export const DialogDataReport = (props) => {
  const {
    selectedDataFilters,
    setSelectedDataFilters,
    fetchBatteryData,
    fetchBatteryFamilies,
    familiesData,
    setPage,  
  } = useAppContext();

  const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
  const checkedIcon = <CheckBoxIcon fontSize="large" />;
  const [error, setError] = useState({
    startDate: false,
    endDate: false,
    families: false,
    source: false,
  });
  
  const [source, setSource] = useState("");

  const [isStartDateDisabled,setIsStartDateDisabled] = useState(false);
  const [isEndDateDisabled,setIsEndDateDisabled] = useState(false);
  
  useEffect(() => {
    setError((old) => ({
      ...old,
      startDate: false,
    }));
  }, [selectedDataFilters.startDate]);
  
  useEffect(() => {
    setError((old) => ({
      ...old,
      endDate: false,
    }));
  }, [selectedDataFilters.endDate]);
  
  useEffect(() => {
    setError((old) => ({
      ...old,
      families: false,
    }));
  }, [selectedDataFilters.families.length]);
  
  useEffect(() => {
    setError((old) => ({
      ...old,
      source: false,
    }));
  }, [selectedDataFilters.source]);

  const { openFilterDialog, handleFilterDialogClose } = props;

   useEffect(() => {
    if(source){
      fetchBatteryFamilies(source);
    }
   }, [source]);

  const handleFiltersChange = (event) => {
    const { name, value } = event.target;

  
    // if (name === "endDate") {
    //   console.log("vallllleee", value);
    //   const endDate = new Date(value);
    //   const startDate = new Date(selectedDataFilters.startDate);

    //   // If end date is before start date, update start date to match end date
    //   if (value < selectedDataFilters.startDate) {
    //     console.log("hiiii", value, selectedDataFilters.startDate);
    //     setSelectedDataFilters({
    //       ...selectedDataFilters,
    //       startDate: value,
    //       [name]: value,
    //     });
    //     return; // Exit function to prevent updating state again below
    //   }
    // }

    // // If the changed field is the "Start Date" field
    // if (name === "startDate") {
    //   const startDate = new Date(value);
    //   const endDate = new Date(selectedDataFilters.endDate);

    //   // If start date is after end date, update end date to match start date
    //   if (value > selectedDataFilters.endDate) {
    //     setSelectedDataFilters({
    //       ...selectedDataFilters,
    //       endDate: value,
    //       [name]: value,
    //     });
    //     return; // Exit function to prevent updating state again below
    //   }
    // }

    setSelectedDataFilters({
      ...selectedDataFilters,
      [name]: value,
    });
  };

  const handleResetFilter = () => {
    setSource("");
    setIsStartDateDisabled(false);
    setIsEndDateDisabled(false);
    setIsBinVinLink(false);
    setSelectedDataFilters({
      startDate: null,
      endDate: null,
      families: [],
      fullDetails: true,
      source:false,
      isBinVinLink: false,
    });
  };

  const [isBinVinLink,setIsBinVinLink] = useState(false);

  const handleSourceFilter = (value) => {
    let startDate = selectedDataFilters.startDate;
    let endDate = selectedDataFilters.endDate;
    if(value === "stellantis"){
      setIsBinVinLink(false);
      setIsStartDateDisabled(true);
      setIsEndDateDisabled(true);
      startDate = null;
      endDate = null;
    }
    if(value === "tmc"){
      setIsStartDateDisabled(false);
      setIsEndDateDisabled(false);
    }
    setSelectedDataFilters({...selectedDataFilters,source:value,families:[],startDate,endDate});
    setSource(value);
    
  }

  const handleBinVinLink = () => {
    const binVinLink = isBinVinLink;
    setIsBinVinLink(!binVinLink);
    setSelectedDataFilters({...selectedDataFilters,isBinVinLink:!binVinLink})
  }

  const handleApplyFilter = () => {
    setError({
      // startDate: !selectedDataFilters.startDate,
      // endDate: !selectedDataFilters.endDate,
      // families: !selectedDataFilters.families.length,
      source: !selectedDataFilters.source,
    });

    if (
      // selectedDataFilters.startDate ||
      // selectedDataFilters.endDate ||
      // selectedDataFilters.families.length ||
      selectedDataFilters.source
    ) {
      fetchBatteryData();
      handleFilterDialogClose();
      setPage(0);
    }
  };

  return (
    <Dialog
      open={openFilterDialog}
      onClose={handleFilterDialogClose}
      maxWidth={false}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            // width: "100%",
            // maxWidth: "500px", // Set your width here
            minHeight: "18rem",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
        }}
      >
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "4px" }}>
        <Typography variant="body1" sx={{ fontSize: "16px", color: "black", marginRight: "2px", lineHeight: "normal" }}>
           BIN-VIN Link
        </Typography>
        <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={isBinVinLink}
        onChange= {handleBinVinLink}
        disabled={source === "stellantis" ? true : false}
      />
      </Grid>
      </Grid>
        <Grid
          sx={{
            display: "flex",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid  
           sx={{
              display: "flex",
              flexDirection: "column",
              height: "80px",
            }}
            >
            <UploadDropDown
            selectStyle={{
              color:"black",
              "& .MuiOutlinedInput-root":{
                color:"black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black", // Default border color is black
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "black", // Keep black on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#1976d2 !important", // Default Material-UI behavior on focus (clicked)
              },
              "& .MuiSvgIcon-root":{
                color:"rgba(0,0,0,0.7)"
              }
            }}
            inputStyle={{
                color:"black"
            }}
              inputLabel={"Source"}
              source={sources}
              sourceValue={source}
              setSourceValue = {handleSourceFilter}
              //   processedData={}
            />
            {error?.source && (
              <Typography
                variant="body2"
                sx={{ color: "red", paddingTop: "1rem" }}
              >
                Source selection is Mandatory
              </Typography>
            )}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "80px",
            }}
          >
            <MultiSelect
              sx={{height: "40px"}}
              sources={familiesData}
              sourceProcessingStatistics={selectedDataFilters?.families}
              setSourceProcessingStatistics={(e) =>
                setSelectedDataFilters({ ...selectedDataFilters, families: e })
              }
              isLightTheme={false}
              heading="Battery Family"
            />

            {/* {error?.families &&
              !selectedDataFilters.startDate &&
              !selectedDataFilters.endDate && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  Please select at least one Battery Family
                </Typography>
              )} */}
          </Grid>
          <Grid
            sx={{ display: "flex", flexDirection: "column", height: "80px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date of Manufacturing"
                disabled={ isStartDateDisabled}
                disableFuture
                value={dayjs(selectedDataFilters.startDate)}
                format="DD/MM/YYYY"
                maxDate={selectedDataFilters?.endDate}
                onChange={(value, date) =>
                  handleFiltersChange({
                    target: {
                      name: "startDate",
                      value: dayjs(value),
                    },
                  })
                }
              />
              {/* </DemoContainer> */}
            </LocalizationProvider>
            {/* {error?.startDate &&
              !selectedDataFilters.families.length &&
              !selectedDataFilters.endDate && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  Please select a Start Date of Manufacturing
                </Typography>
              )} */}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              height: "80px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date of Manufacturing"
                disabled={ isEndDateDisabled}
                disableFuture
                value={dayjs(selectedDataFilters.endDate)}
                format="DD/MM/YYYY"
                minDate={selectedDataFilters?.startDate}
                onChange={(value, date) =>
                  handleFiltersChange({
                    target: {
                      name: "endDate",
                      value: dayjs(value),
                    },
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            gap: "5rem",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleResetFilter} fontSize="52px">
            Reset
          </Button>
          <Button onClick={handleApplyFilter}>Apply</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
