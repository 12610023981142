import { Grid, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../context/AppContext";

export const BatteryUploadCharts = () => {
  const { graphAddedCount, addedgraphError } = useAppContext();
  const chartRef = useRef(null);

  const [options, setOptions] = useState({});

  // useEffect(() => {
  //   fetchReportData();
  // }, [0, 20]);

  const colorMap = { TMC: "#eb0a1e", STELLANTIS: "#9B9999" };

  // const options = {
  //   chart: {
  //     type: "column",
  //   },
  //   credits: { enabled: false },

  //   title: {
  //     text: "Batteries Added",
  //     align: "left",
  //   },
  //   xAxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     crosshair: true,
  //     accessibility: {
  //       description: "Months",
  //     },
  //   },
  //   yAxis: {
  //     min: 0,
  //     title: {
  //       text: "",
  //     },
  //   },
  //   legend: {
  //     align: "right",
  //     verticalAlign: "top",
  //     symbolRadius: 0,
  //     symbolHeight: 10,
  //     symbolWidth: 12,
  //   },
  //   tooltip: {
  //     valueSuffix: " Records",
  //   },
  //   plotOptions: {
  //     column: {
  //       pointPadding: 0.1,
  //       borderWidth: 0,
  //     },
  //   },
  //   series: [],
  // };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      // const chart = chartRef.current.chart;
      // graphAddedCount?.forEach((res) => {
      //   console.log("sadas", "====");
      //   chart.addSeries({
      //     name: res.source,
      //     color: colorMap[res.source],
      //     data: res.data,
      //   });
      // });
    }

    setOptions(() => {
      return {
        chart: {
          type: "column",
          // width: "572px",
          // height: "200px",
        },
        credits: { enabled: false },

        title: {
          text: "Batteries Added",
          align: "left",
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          crosshair: true,
          accessibility: {
            description: "Months",
          },
        },
        yAxis: {
          // type: "logarithmic",
          min: 0,
          allowDecimals: false,
          title: {
            text: "",
          },
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          symbolRadius: 0,
          symbolHeight: 10,
          symbolWidth: 12,
        },
        tooltip: {
          valueSuffix: " Records",
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
            groupPadding: 0,
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.y > 0) return this.y;
              },
            },
            // distance: 20,
          },
        },
        series: graphAddedCount?.map((res) => {
          return {
            name: res.source,
            color: colorMap[res.source],
            data: res.data,
          };
        }),
      };
    });
  }, [graphAddedCount]);
  if (addedgraphError) {
    return (
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">
          Error : {addedgraphError ? addedgraphError : "Something Went Wrong"}
        </Typography>
      </Grid>
    );
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
};
