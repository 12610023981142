import axios from "axios";
// import { REACT_APP_base_url } from "../env";
const { REACT_APP_base_url } = process.env;

const API_URL = `${REACT_APP_base_url}/battery-service-batch`;
const API_URL2 = `${REACT_APP_base_url}/battery-service`;
// const API_URL3 = `${REACT_APP_base_url}/battery-service/public`;

// const fetchIdTokenRequest = useAppContext();
const createApiInstance = (REACT_APP_base_url) => {
  const api = axios.create({ REACT_APP_base_url });

  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "idToken"
      )}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // Refresh token and retry API call
        // fetchIdTokenRequest();
      }
      return Promise.reject(error);
    }
  );

  return api;
};

const api1 = createApiInstance(API_URL);
const api2 = createApiInstance(API_URL2);

const ApiService = {
  get: async (url, queryParams = {}, headers = {}) => {
    try {
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const fullUrl = queryString
        ? `${API_URL}/${url}?${queryString}`
        : `${API_URL}/${url}`;

      const response = await api1.get(fullUrl, {
        headers,
      });
      return response.data;
    } catch (error) {
      throw error;
      // throw error.response.data;
    }
  },
  post: async (url, data, queryParams, headers = {}) => {
    try {
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const fullUrl = queryString
        ? `${API_URL}/${url}?${queryString}`
        : `${API_URL}/${url}`;
      const response = await api1.post(fullUrl, data, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  put: async (url, data, headers = {}) => {
    try {
      const response = await api1.put(`${API_URL}/${url}`, data, {
        headers,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url, headers = {}) => {
    try {
      const response = await api1.delete(`${API_URL}/${url}`, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export const ApiService2 = {
  get: async (url, queryParams = {}, headers = {}) => {
    try {
      const queryString = Object?.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const fullUrl = queryString
        ? `${API_URL2}/${url}?${queryString}`
        : `${API_URL2}/${url}`;
      const response = await api2.get(fullUrl, {
        headers,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  post: async (url, data, queryParams, headers = {}, responseType = "json") => {
    try {
      const queryString = Object?.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const fullUrl = queryString
        ? `${API_URL2}/${url}?${queryString}`
        : `${API_URL2}/${url}`;
      const response = await api2.post(fullUrl, data, {
        headers,
        responseType,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  put: async (url, data, headers = {}) => {
    try {
      const response = await api2.put(`${API_URL2}/${url}`, data, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url, headers = {}) => {
    try {
      const response = await api2.delete(`${API_URL2}/${url}`, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export const ApiService3 = {
  get: async (url, queryParams = {}) => {
    try {
      const queryString = Object?.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const fullUrl = queryString
        ? `${API_URL2}/${url}?${queryString}`
        : `${API_URL2}/${url}`;
      const response = await axios.get(fullUrl, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiService;
