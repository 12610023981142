import { Button } from "@mui/material";

export const UploadButton = (props) => {
  const handleUpload = () => {
    props.handleClick();
  };
  return (
    <Button
      disabled={props.disabled}
      sx={{
        position: "absolute",
        // left: "0",
        right: "35px",
        bottom: "50px",
        // color: "white",
        paddingY: "1rem",
        border: "1px solid black",
        // backgroundColor: "black",
        color: props.disabled ? "rgba(0, 0, 0, 0.26)" : "white", // Gray out text color when disabled
        backgroundColor: props.disabled ? "rgba(0, 0, 0, 0.12)" : "black", // Gray out background color when disabled
        paddingX: "3rem",
        ":hover": {
          bgcolor: "primary.main", // theme.palette.primary.main
          color: "white",
        },
      }}
      onClick={handleUpload}
    >
      Upload
    </Button>
  );
};
