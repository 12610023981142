export const styles = {
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    padding: "16px",
    borderRadius: "8px",
    height: 75,
  },
};
