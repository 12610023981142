import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom/dist";
import { ReservedConst } from "../../../assets/consts/ReservedConst";
import ContactUsForm from "../../molecules/ContactUsForm/ContactUsForm";
import { styles } from "./styles";
// import { REACT_APP_base_url } from "../../../env";

export const Footer = (props) => {
  const { REACT_APP_base_url } = process.env;
  const { setShowPopup, setUploadSuccess } = props;
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const fetchPrivacy = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_base_url}/battery-service/public/v1/batteries/document?file=policy`,
        {
          responseType: "blob", // Important
        }
      );

      // Create a blob object from the binary data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching PDF:", error);
      setUploadSuccess("failedPrivacyPolicy");
      setShowPopup(true);
    }
  };
  const fetchFaq = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_base_url}/battery-service/public/v1/batteries/document?file=faq`,
        {
          responseType: "blob", // Important
        }
      );

      // Create a blob object from the binary data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching PDF:", error);
      setUploadSuccess("failedFaq");
      setShowPopup(true);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Grid
      container
      sx={(theme) => ({
        padding: "4px 26px 0.5rem",
        gap: "2rem",
        [theme.breakpoints.down(500)]: {
          flexDirection: "column",
          gap: "4px",
        },
        // textAlign: "right",
      })}
    >
      <Typography
        sx={{
          ...styles.footer,
          // color: "white",
          cursor: "pointer",
          color:
            location?.pathname.startsWith("/bin/") ||
            location?.pathname.startsWith("/batterymodel/")
              ? "black"
              : "white",
        }}
        onClick={fetchPrivacy}
      >
        Privacy & Cookie Policy
      </Typography>

      <Typography
        sx={{
          ...styles.footer,
          cursor: "pointer",
          color:
            location?.pathname.startsWith("/bin/") ||
            location?.pathname.startsWith("/batterymodel/")
              ? "black"
              : "white",
        }}
        onClick={fetchFaq}
      >
        FAQ
      </Typography>
      <Typography
        sx={{
          ...styles.footer,
          cursor: "pointer",
          color:
            location?.pathname.startsWith("/bin/") ||
            location?.pathname.startsWith("/batterymodel/")
              ? "black"
              : "white",
        }}
        onClick={handleClickOpen}
      >
        Contact Us
      </Typography>
      <ContactUsForm open={open} setOpen={setOpen} />
      <Typography
        sx={(theme) => ({
          ...styles.footer,
          color:
            location?.pathname.startsWith("/bin/") ||
            location?.pathname.startsWith("/batterymodel/")
              ? "black"
              : "white",
          flexGrow: 1,
          textAlign: "right",
          [theme.breakpoints.down(500)]: {
            textAlign: "left",
          },
        })}
      >
        {ReservedConst}
      </Typography>
    </Grid>
  );
};
