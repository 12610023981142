import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
export const theme = createTheme({
  typography: {
    fontFamily: "ToyotaType-Regular",
    h1: {
      fontSize: "1.5rem",
      color: "red",
    },
    h2: {
      fontSize: "1.25rem",
      color: "red",
      fontWeight: "575",
    },
  },
  custom: {
    textFieldColor: "#ffff",
  },
  palette: {
    background: {
      default: "#111111",
    },
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: "red", // Change the color to red
      },
    },
  },
});
