import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styles } from "./styles";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

function BatteryStats({ dataToDisplay, processedCount }) {
  return (
    <Card sx={styles.card}>
      <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h6" color="text" sx={styles.text}>
            {dataToDisplay}
          </Typography>
          <Typography variant="h4" component="div" sx={styles.text}>
            {processedCount}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default BatteryStats;
