export const styles = {
  parentContainer: {
    backgroundColor: "#2196f3",
    borderRadius: "8px",
    // width: "100%",
    // border: "5px solid green",
    padding: "10px 16px 24px 16px ",
    height: "16rem",
  },
};
