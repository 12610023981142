import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Landing } from "./pages/Landing/Landing";
import { CookiesPage } from "./pages/Cookies/CookiesPage";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { CircularProgress, Grid, Typography } from "@mui/material";
import ProtectedRoute from "./ProtectedRoute";
import { BinDetails } from "./components/organisms/VinDetails/BinDetails";
import { useAppContext } from "./context/AppContext";
import MainLayout from "./layout/mainLayout";
import { BatteryStatusReport } from "./pages/BatteryStatusReport/BatteryStatusReport";
import { ManualDataUpload } from "./pages/ManualDataUpload/ManualDataUpload";

import { useState } from "react";
import { StellantisBinDetails } from "./components/organisms/StellantisBinDetails/StellantisBinDetails";
import { BatteryDataReport } from "./pages/BatterDataReport/BatteryDataReport";

export const MainRouter = (props) => {
  const { fetchIdTokenRequest, idToken } = useAppContext();
  // const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("idToken");

  useEffect(() => {
    // You can use this useEffect to set a loading state until MSAL authentication status is determined
    if (
      (inProgress === "none" && token && accounts?.length > 0) ||
      (inProgress === "none" && accounts?.length === 0 && !token)
    ) {
      setLoading(false);
    }
  }, [inProgress, token, accounts]);

  // useEffect(() => {
  //   const account = accounts[0];
  //   const refreshToken = async () => {
  //     if (!instance) {
  //       // MSAL instance is not initialized yet, so we can't refresh the token
  //       return;
  //     }

  //     const silentRequest = {
  //       scopes: ["User.Read"], // replace with your API scopes
  //       account: account,
  //     };

  //     try {
  //       const response = await instance.acquireTokenSilent(silentRequest);
  //       // response.accessToken will contain your new access token
  //       localStorage.setItem("idToken", response.idToken);
  //     } catch (err) {
  //       // If a silent request fails, it may be because the user needs to interact with the OAuth server.
  //       // You can fallback to an interactive method here.
  //       const interactiveResponse = await instance.acquireTokenPopup(
  //         silentRequest
  //       );
  //       localStorage.setItem("idToken", interactiveResponse.idToken);
  //     }
  //   };

  //   refreshToken();
  // }, [accounts, instance]);
  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     instance
  //       .ssoSilent({
  //         scopes: ["user.read"],
  //       })
  //       .then((response) => {
  //         instance.setActiveAccount(response.account);
  //       })
  //       .catch((error) => {
  //         if (error instanceof InteractionRequiredAuthError) {
  //           instance.loginRedirect({
  //             scopes: ["user.read"],
  //           });
  //         }
  //       });
  //   }
  // }, [instance, isAuthenticated]);

  useEffect(() => {
    fetchIdTokenRequest();
  }, [instance, accounts, inProgress, idToken]);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <MainLayout>
            <Landing />
          </MainLayout>
        }
      />
      <Route
    path="/cookies"
    element={

        <CookiesPage />
  
    }
  />
      <Route
        path="/bin/:paramBinNumber"
        element={
          <MainLayout>
            {loading ? (
              <Grid
                sx={{
                  width: "100vw",
                  height: "100vh",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <BinDetails isAuthenticated={isAuthenticated} />
            )}
          </MainLayout>
        }
      />
      <Route
        path="/batterymodel/:paramFamilyNumber"
        element={
          <MainLayout>
            {loading ? (
              <Grid
                sx={{
                  width: "100vw",
                  height: "100vh",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <StellantisBinDetails isAuthenticated={isAuthenticated} />
            )}
          </MainLayout>
        }
      />
      {/* <ProtectedRoute exact path="/admin/dashboard" component={Dashboard} /> */}

      <Route element={<ProtectedRoute />}>
        <Route
          path="/management/dashboard"
          element={
            <MainLayout>
              <Dashboard />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/management/batterydatareport"
          element={
            <MainLayout>
              <BatteryDataReport />
            </MainLayout>
          }
        />
        <Route
          path="/management/batterystatusreport"
          element={
            <MainLayout>
              <BatteryStatusReport />
            </MainLayout>
          }
        />
        <Route
          path="/management/manualdataupload"
          element={
            <MainLayout>
              <ManualDataUpload />
            </MainLayout>
          }
        />
        <Route
          path="/management/configuration"
          element={
            <MainLayout>
              <BatteryStatusReport />
            </MainLayout>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <MainLayout>
            <Typography
              variant="h1"
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Page Not Found
            </Typography>
          </MainLayout>
        }
      />
    </Routes>
  );
};
