import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import MultiSelect from "../../atoms/MultiSelect/MultiSelect";

export const DialogDataReport = (props) => {
  const {
    selectedDataFilters,
    setSelectedDataFilters,
    fetchBatteryData,
    fetchBatteryFamilies,
    familiesData,
    setPage,
  } = useAppContext();

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
    families: false,
  });

  useEffect(() => {
    setError((old) => ({
      ...old,
      startDate: false,
    }));
  }, [selectedDataFilters.startDate]);
  useEffect(() => {
    setError((old) => ({
      ...old,
      endDate: false,
    }));
  }, [selectedDataFilters.endDate]);
  useEffect(() => {
    setError((old) => ({
      ...old,
      families: false,
    }));
  }, [selectedDataFilters.families.length]);

  const { openFilterDialog, handleFilterDialogClose } = props;

  useEffect(() => {
    fetchBatteryFamilies();
  }, []);

  const handleFiltersChange = (event) => {
    const { name, value } = event.target;

    // if (name === "endDate") {
    //   console.log("vallllleee", value);
    //   const endDate = new Date(value);
    //   const startDate = new Date(selectedDataFilters.startDate);

    //   // If end date is before start date, update start date to match end date
    //   if (value < selectedDataFilters.startDate) {
    //     console.log("hiiii", value, selectedDataFilters.startDate);
    //     setSelectedDataFilters({
    //       ...selectedDataFilters,
    //       startDate: value,
    //       [name]: value,
    //     });
    //     return; // Exit function to prevent updating state again below
    //   }
    // }

    // // If the changed field is the "Start Date" field
    // if (name === "startDate") {
    //   const startDate = new Date(value);
    //   const endDate = new Date(selectedDataFilters.endDate);

    //   // If start date is after end date, update end date to match start date
    //   if (value > selectedDataFilters.endDate) {
    //     setSelectedDataFilters({
    //       ...selectedDataFilters,
    //       endDate: value,
    //       [name]: value,
    //     });
    //     return; // Exit function to prevent updating state again below
    //   }
    // }

    setSelectedDataFilters({
      ...selectedDataFilters,
      [name]: value,
    });
  };

  const handleResetFilter = () => {
    setSelectedDataFilters({
      startDate: null,
      endDate: null,
      families: [],
      fullDetails: true,
    });
  };

  const handleApplyFilter = () => {
    setError({
      startDate: !selectedDataFilters.startDate,
      endDate: !selectedDataFilters.endDate,
      families: !selectedDataFilters.families.length,
    });

    if (
      selectedDataFilters.startDate ||
      selectedDataFilters.endDate ||
      selectedDataFilters.families.length
    ) {
      fetchBatteryData();
      handleFilterDialogClose();
      setPage(0);
    }
  };

  return (
    <Dialog
      open={openFilterDialog}
      onClose={handleFilterDialogClose}
      maxWidth={false}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            // width: "100%",
            // maxWidth: "500px", // Set your width here
            minHeight: "18rem",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "80px",
            }}
          >
            <MultiSelect
              sources={familiesData}
              sourceProcessingStatistics={selectedDataFilters?.families}
              setSourceProcessingStatistics={(e) =>
                setSelectedDataFilters({ ...selectedDataFilters, families: e })
              }
              isLightTheme={false}
              heading="Battery Family"
            />

            {error?.families &&
              !selectedDataFilters.startDate &&
              !selectedDataFilters.endDate && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  Please select at least one Battery Family
                </Typography>
              )}
          </Grid>
          <Grid
            sx={{ display: "flex", flexDirection: "column", height: "80px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date of Manufacturing"
                disableFuture
                value={dayjs(selectedDataFilters.startDate)}
                format="DD/MM/YYYY"
                maxDate={selectedDataFilters?.endDate}
                onChange={(value, date) =>
                  handleFiltersChange({
                    target: {
                      name: "startDate",
                      value: dayjs(value),
                    },
                  })
                }
              />
              {/* </DemoContainer> */}
            </LocalizationProvider>
            {error?.startDate &&
              !selectedDataFilters.families.length &&
              !selectedDataFilters.endDate && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  Please select a Start Date of Manufacturing
                </Typography>
              )}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              height: "80px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DemoContainer components={["DatePicker"]}> */}
              <DatePicker
                label="End Date of Manufacturing"
                disableFuture
                value={dayjs(selectedDataFilters.endDate)}
                format="DD/MM/YYYY"
                minDate={selectedDataFilters?.startDate}
                onChange={(value, date) =>
                  handleFiltersChange({
                    target: {
                      name: "endDate",
                      value: dayjs(value),
                      // value: dayjs(value).format("DD/MM/YYYY"),
                    },
                  })
                }
              />
              {/* </DemoContainer> */}
            </LocalizationProvider>
            {error?.endDate &&
              !selectedDataFilters.families.length &&
              !selectedDataFilters.startDate && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  Please select a End Date of Manufacturing
                </Typography>
              )}
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            gap: "5rem",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleResetFilter} fontSize="52px">
            Reset
          </Button>
          <Button onClick={handleApplyFilter}>Apply</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
