import { useMsal } from "@azure/msal-react";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService, { ApiService2 } from "../services/ApiService";
// import { REACT_APP_base_url } from "../env";

const AppContext = createContext(null);

export const sources = [
  {
    label: "TMC",
    value: "tmc",
  },
  {
    label: "Stellantis",
    value: "stellantis",
  },
];

export const AppProvider = ({ children }) => {
  const { REACT_APP_base_url } = process.env;
  const { instance, accounts } = useMsal();
  const [binNumber, setBinNumber] = useState("");
  const [familyNumber, setFamilyNumber] = useState("");
  const [authError, setAuthError] = useState("");
  const [binError, setBinError] = useState("");
  const [addedgraphError, setAddedGraphError] = useState("");
  const [failedgraphError, setFailedGraphError] = useState("");
  const [statsError, setStatsError] = useState("");
  const [tableDataError, setTableDataError] = useState("");
  const [batteryDataError, setBatteryDataError] = useState("");

  const [auditTrailError, setAuditTrailError] = useState("");
  const [recordLevelError, setRecordLevelError] = useState("");
  const [recordLevelLoading, setRecordLevelLoading] = useState(false);

  const [vinNumber, setVinNumber] = useState("");
  const [binDetails, setBinDetails] = useState({});
  const [familyDetails, setFamilyDetails] = useState({});

  const [auditTrailData, setAuditTrailData] = useState([]);
  const [auditTrailLoading, setAuditTrailLoading] = useState(false);
  const [recordLevelData, setRecordLevelData] = useState([]);

  const [reportData, setReportData] = useState({});
  const [batteryData, setBatteryData] = useState({});

  const [loading, setLoading] = useState(true);
  const [graphAddedCount, setGraphAddedCount] = useState([]);
  const [graphFailedCount, setGraphFailedCount] = useState([]);
  const [processedCount, setProcessedCount] = useState({});
  const [sourceProcessingStatistics, setSourceProcessingStatistics] =
    useState(sources);

  const [yearProcessingStatistics, setYearProcessingStatistics] =
    useState("2024");
  const [sourceProcessed, setSourceProcessed] = useState(sources);
  const [sourceFilter, setSourceFilter] = useState(sources);
  const [timeProcessed, setTimeProcessed] = useState("last 7 days");

  const [familiesData, setFamiliesData] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    source: [],
    status: "",
    startDate: "",
    endDate: "",
  });

  const [selectedDataFilters, setSelectedDataFilters] = useState({
    startDate: null,
    endDate: null,
    families: [],
    fullDetails: true,
  });

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const rowsPerPage = 7;
  const [idToken, setIdToken] = useState("");

  // const fetchIdTokenRequest = () => {
  //   const idTokenRequest = {
  //     scopes: ["user.read"],
  //     account: accounts[0],
  //   };

  //   if (inProgress === InteractionStatus.None) {
  //     instance
  //       .acquireTokenSilent(idTokenRequest)
  //       .then((accessTokenResponse) => {
  //         // Acquire token silent success
  //         let idTokens = accessTokenResponse.idToken;
  //         // Call your API with token
  //         // console.log(idTokens.trim(2));
  //         setIdToken(idTokens);
  //         localStorage.setItem("idToken", idTokens);

  //         const decodedToken = jwtDecode(idTokens);
  //         const currentTime = Date.now() / 1000;
  //         const expiryTime = decodedToken.exp;

  //         if (currentTime > expiryTime) {
  //           handleLogout(); // Call the logout function when the token is expired
  //         } else {
  //           const refreshTime = Math.max(
  //             (expiryTime - currentTime - 3540) * 1000,
  //             60000
  //           );

  //           // Save the timeout ID to clear it later
  //           const timeoutId = setTimeout(fetchIdTokenRequest, refreshTime);

  //           // Clear the timeout when the component unmounts
  //           return () => clearTimeout(timeoutId);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //         if (error instanceof InteractionRequiredAuthError) {
  //           instance.acquireTokenRedirect(idTokenRequest);
  //         }
  //       });
  //   }
  // };

  // const fetchIdTokenRequest = () => {
  //   const idTokenRequest = {
  //     scopes: ["user.read"],
  //     account: accounts[0],
  //     forceRefresh: false, // Set to true to skip token cache and force a new token request
  //   };

  //   // Get the stored idToken
  //   const idToken = localStorage.getItem("idToken");

  //   if (idToken) {
  //     // Decode the token to get the expiry time
  //     const decodedToken = jwtDecode(idToken);
  //     const currentTime = Date.now() / 1000;
  //     const expiryTime = decodedToken.exp;
  //     console.log(new Date(currentTime * 1000), new Date(expiryTime * 1000));
  //     console.log(new Date(currentTime * 1000), new Date(expiryTime * 1000));

  //     if (currentTime > expiryTime) {
  //       // If the idToken is expired, force a refresh
  //       // console.log("hiiii");
  //       idTokenRequest.forceRefresh = true;
  //       // console.log("hiii");
  //       // return;
  //     }
  //   }

  //   if (inProgress === InteractionStatus.None) {
  //     // console.log("hiii");
  //     instance
  //       .acquireTokenSilent(idTokenRequest)
  //       .then((accessTokenResponse) => {
  //         // Acquire token silent success
  //         let idTokens = accessTokenResponse.idToken;
  //         // Call your API with token
  //         // console.log(idTokens.trim(2));
  //         setIdToken(idTokens);
  //         localStorage.setItem("idToken", idTokens);
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //         if (error instanceof InteractionRequiredAuthError) {
  //           // If silent token acquisition fails due to interaction required,
  //           // fallback to an interactive method
  //           idTokenRequest.forceRefresh = true;
  //           instance
  //             .acquireTokenSilent(idTokenRequest)
  //             .then((accessTokenResponse) => {
  //               let idTokens = accessTokenResponse.idToken;
  //               setIdToken(idTokens);
  //               localStorage.setItem("idToken", idTokens);
  //             })
  //             .catch((error) => {
  //               console.log("error", error);
  //             });
  //         }
  //       });
  //   }
  // };
  let refreshIntervalId = null;

  const fetchIdTokenRequest = () => {
    let idTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
      forceRefresh: false, // Set to true to skip token cache and force a new token request
    };

    instance
      .acquireTokenSilent(idTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let idToken = accessTokenResponse.idToken;
        setIdToken(idToken);
        localStorage.setItem("idToken", idToken);

        if (idToken) {
          // Decode the token to get the expiry time
          let decodedToken = jwtDecode(idToken);
          let expiryTime = decodedToken.exp;

          refreshIntervalId = setInterval(() => {
            const currentTime = Date.now() / 1000;
            if (currentTime > expiryTime) {
              // If the idToken is expired, force a refresh
              idTokenRequest.forceRefresh = true;

              // Attempt to acquire a new token
              instance
                .acquireTokenSilent(idTokenRequest)
                .then((accessTokenResponse) => {
                  // Acquire token silent success
                  idToken = accessTokenResponse.idToken;
                  setIdToken(idToken);
                  localStorage.setItem("idToken", idToken);

                  // Decode the new token to get the new expiry time
                  let decodedToken = jwtDecode(idToken);
                  expiryTime = decodedToken.exp;
                })
                .catch((error) => {
                  try {
                    localStorage.removeItem("idToken");
                    instance.logout();

                    if (refreshIntervalId) {
                      clearInterval(refreshIntervalId);
                      refreshIntervalId = null;
                    }
                  } catch (logoutError) {
                    console.error("Failed to log out", logoutError);
                  }
                });
            }
          }, 5000);
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  // Get the stored idToken

  // Set interval to check if token is expired and force refresh every 5 seconds

  const handleLogout = async () => {
    try {
      localStorage.removeItem("idToken");
      instance.logout();
      if (refreshIntervalId) {
        clearInterval(refreshIntervalId);
        refreshIntervalId = null;
      }
    } catch (error) {}
  };

  const fetchReportData = async () => {
    setLoading(true);
    setReportData((old) => {
      return {
        ...old,
        content: [],
      };
    });

    const body = Object.entries(selectedFilters)
      .filter(
        ([key, value]) => value !== null && value !== undefined && value !== ""
      )
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    ApiService.post(
      "v1/jobs/all",
      {
        ...body,
        source: body.source.map((_) => _.value),
      },
      {
        page: page,
        size: rowsPerPage,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setTableDataError("");
        setLoading(false);
        setReportData(resp);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 403) {
          setAuthError("User is not authorised to view this page");
        } else if (err?.name == "AxiosError") {
          setTableDataError("Battery status report is not available");
        }
      });
  };

  const fetchBatteryData = async () => {
    setLoading(true);
    setBatteryData((old) => {
      return {
        ...old,
        content: [],
      };
    });

    // const body = Object.entries(selectedDataFilters)
    //   .filter(
    //     ([key, value]) => value !== null && value !== undefined && value !== ""
    //   )
    //   .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    // // if (!body.startDate || !body.endDate || !body.families) {
    // //   return;
    // // }
    let body = {
      ...selectedDataFilters,
    };

    // Format dates if they exist
    if (body.startDate) {
      body.startDate = dayjs(body.startDate).format("DD/MM/YYYY");
    }
    if (body.endDate) {
      body.endDate = dayjs(body.endDate).format("DD/MM/YYYY");
    }
    if (body.families.length > 0) {
      body.families = body.families.map((fam) => fam.value);
    }

    ApiService2.post(
      "v1/batteries/search",
      {
        ...body,
      },
      {
        page: page,
        size: rowsPerPage,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setBatteryDataError("");
        setLoading(false);
        setBatteryData(() => {
          return {
            ...resp,
            content: resp?.content.map((_, i) => ({
              ..._,
              id: i,
            })),
          };
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 403) {
          setAuthError("User is not authorised to view this page");
        } else if (err?.name == "AxiosError") {
          setBatteryDataError("Battery status report is not available");
        }
      });
  };
  const downloadCsvReport = async () => {
    // setBatteryData((old) => {
    //   return {
    //     ...old,
    //     content: [],
    //   };
    // });
    let body = {
      ...selectedDataFilters,
    };

    // Format dates if they exist
    if (body.startDate) {
      body.startDate = dayjs(body.startDate).format("DD/MM/YYYY");
    }
    if (body.endDate) {
      body.endDate = dayjs(body.endDate).format("DD/MM/YYYY");
    }
    if (body.families.length > 0) {
      body.families = body.families.map((fam) => fam.value);
    }

    ApiService2.post(
      "v1/batteries/download-detailed-report",
      {
        ...body,
      },
      {},
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
        // "Content-Type": "application/json",
      },
      "blob"
    )
      .then((response) => {
        const currentDate = new Date();
        const formattedDate = dayjs(currentDate).format("DDMMYY_HHmmss");

        const url = window.URL.createObjectURL(
          new Blob([response], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Battery Data Report_${formattedDate}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.error("Error downloading Excel report:", err);
      });
  };

  const fetchBatteryFamilies = () => {
    setLoading(true);

    ApiService2.get(
      "v1/batteries/families/ids",
      {},
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setLoading(false);
        // setSelectedDataFilters(resp);
        const temp = resp.map((_) => {
          return {
            label: _,
            value: _,
          };
        });
        // const familiesWithDataAll = [{ label: "All", value: "All" }, ...temp];
        const familiesWithDataAll = temp;
        setFamiliesData(familiesWithDataAll);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.status === 403) {
          setAuthError("User is not authorised to view this page");
          navigate("/");
        } else if (err?.name == "AxiosError") {
          setAddedGraphError("Added batteries count is not available");
        }
      });
  };
  // const fetchGraphBatteriesAddedCount = () => {
  //   setLoading(true);

  //   ApiService2.get(
  //     "v1/batteries/statistics",
  //     {
  //       type: "month-wise",
  //       source: sourceProcessingStatistics.map((_) => _.value),
  //       time: yearProcessingStatistics,
  //     },
  //     {
  //       Authorization: `Bearer ${localStorage.getItem("idToken")}`,
  //     }
  //   )
  //     .then((resp) => {
  //       setLoading(false);
  //       setGraphAddedCount(resp.monthAndSourceWise);
  //     })
  //     .catch((err) => {
  //       setLoading(false);

  //       if (err?.response?.status === 403) {
  //         setAuthError("User is not authorised to view this page");
  //         navigate("/");
  //       } else if (err?.name == "AxiosError") {
  //         setAddedGraphError("Added batteries count is not available");
  //       }
  //     });
  // };

  const fetchGraphBatteriesAddedCount = () => {
    setLoading(true);

    ApiService2.get(
      "v1/batteries/statistics",
      {
        type: "month-wise",
        source: sourceProcessingStatistics.map((_) => _.value),
        time: yearProcessingStatistics,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setLoading(false);
        setGraphAddedCount(resp.monthAndSourceWise);
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.status === 403) {
          setAuthError("User is not authorised to view this page");
          navigate("/");
        } else if (err?.name == "AxiosError") {
          setAddedGraphError("Added batteries count is not available");
        }
      });
  };

  const fetchGraphBatteriesFailedCount = () => {
    setLoading(true);

    ApiService.get(
      "v1/jobs/failed/count",
      {
        source: sourceProcessingStatistics.map((_) => _.value),
        year: yearProcessingStatistics,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setLoading(false);
        setGraphFailedCount(resp);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 403) {
          setAuthError("User is not authorised to view this page");
          navigate("/");
        } else if (err?.name == "AxiosError") {
          setFailedGraphError("Failed batteries count is not available");
        }
      });
  };
  const fetchBatteryProcessedCount = () => {
    setLoading(true);

    ApiService2.get(
      "v1/batteries/statistics",
      {
        type: "summary",
        source: sourceProcessed.map((_) => _.value),
        time: timeProcessed,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setLoading(false);
        setProcessedCount(resp);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 403) {
          setAuthError("User is not authorised to view this page");
        } else if (err?.name == "AxiosError") {
          setStatsError("Processed batteries count is not available");
        }
      });
  };

  const fetchBinNumber = (navigate) => {
    setLoading(true);
    // ApiService2.get(`${REACT_APP_base_url}/public/v1/batteries/search`, {
    //   vin: vinNumber,
    // });
    axios
      .get(`${REACT_APP_base_url}/battery-service/public/v1/batteries/search`, {
        params: {
          vin: vinNumber,
        },
      })
      .then((resp) => {
        setBinError("");
        setLoading(false);
        if (resp.data.Source == "STELLANTIS") {
          setFamilyNumber(resp.data.FamilyDefinition);
          setFamilyDetails(resp.data);
          navigate(`batterymodel/${resp.data.FamilyDefinition}`);
        } else {
          setBinNumber(resp.data.BIN);
          setBinDetails(resp.data);
          navigate(`bin/${resp.data.BIN}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 404) {
          setBinError(
            "Battery Information related to the selected VIN is not available"
          );
        } else if (err?.response?.status === 401) {
          setBinError(
            "Battery Information related to the selected VIN is not available"
          );
        } else if (err?.name == "AxiosError") {
          setBinError("Something went wrong, please try again later");
        }
      });
  };
  const fetchBinDetails = async (binNo) => {
    setLoading(true);

    // const resp = await ApiService2.get(`public/v1/batteries/${binNo}`)

    // if (resp?.response?.status === 404) {
    //   setBinError(
    //     "Battery Information related to the selected VIN is not available"
    //   );
    //   ;
    // } else if (resp?.response?.status === 400) {
    //   setBinError("Invalid VIN format");
    //   ;
    // } else if (resp?.name == "AxiosError") {
    //   setBinError("Something went wrong, please try again later.");
    //   ;
    // }

    // setBinError("");
    // setLoading(false);
    // setBinDetails(resp);

    // ApiService2.get(`public/v1/batteries/${binNo}`)
    axios
      .get(`${REACT_APP_base_url}/battery-service/public/v1/batteries/${binNo}`)
      .then((resp) => {
        setBinError("");
        setLoading(false);
        setBinDetails(resp.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 404) {
          setBinError(
            "Battery Information related to the selected VIN is not available"
          );
        } else if (err?.response?.status === 400) {
          setBinError("Invalid VIN format");
        } else if (err?.name == "AxiosError") {
          setBinError("Something went wrong, please try again later.");
        }
      });
  };

  const fetchFamilyDetails = async (familyNumber) => {
    setLoading(true);

    // const resp = await ApiService2.get(`public/v1/batteries/${binNo}`)

    // if (resp?.response?.status === 404) {
    //   setBinError(
    //     "Battery Information related to the selected VIN is not available"
    //   );
    //   ;
    // } else if (resp?.response?.status === 400) {
    //   setBinError("Invalid VIN format");
    //   ;
    // } else if (resp?.name == "AxiosError") {
    //   setBinError("Something went wrong, please try again later.");
    //   ;
    // }

    // setBinError("");
    // setLoading(false);
    // setBinDetails(resp);

    // ApiService2.get(`public/v1/batteries/search`, {
    //   familyDefinition: familyNumber,
    // })
    axios
      .get(`${REACT_APP_base_url}/battery-service/public/v1/batteries/search`, {
        params: {
          familyDefinition: familyNumber,
        },
      })

      .then((resp) => {
        setBinError("");
        setLoading(false);
        setFamilyDetails(resp.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 404) {
          setBinError(
            "Battery Information related to the selected VIN is not available"
          );
        } else if (err?.response?.status === 400) {
          setBinError("Invalid VIN format");
        } else if (err?.name == "AxiosError") {
          setBinError("Something went wrong, please try again later.");
        }
      });
  };

  const fetchAdminBinNumber = (navigate) => {
    setLoading(true);
    ApiService2.get(
      `v1/batteries/search?vin=${vinNumber}`,
      {},
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setBinError("");
        setLoading(false);
        if (resp.Source == "STELLANTIS") {
          setFamilyNumber(resp.FamilyDefinition);
          setFamilyDetails(resp);
          navigate(`/batterymodel/${resp.FamilyDefinition}`);
        } else {
          setBinNumber(resp.BIN);
          setBinDetails(resp);
          navigate(`/bin/${resp.BIN}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 404) {
          setBinError(
            "Battery Information related to the selected VIN is not available"
          );
        } else if (err?.response?.status === 401) {
          setBinError(
            "Battery Information related to the selected VIN is not available"
          );
        } else if (err?.name == "AxiosError") {
          setBinError("Something went wrong, please try again later");
        }
      });
  };
  const fetchAdminBinDetails = async (binNo, navigate) => {
    setLoading(true);

    // const resp = await ApiService2.get(`public/v1/batteries/${binNo}`)

    // if (resp?.response?.status === 404) {
    //   setBinError(
    //     "Battery Information related to the selected VIN is not available"
    //   );
    //   ;
    // } else if (resp?.response?.status === 400) {
    //   setBinError("Invalid VIN format");
    //   ;
    // } else if (resp?.name == "AxiosError") {
    //   setBinError("Something went wrong, please try again later.");
    //   ;
    // }

    // setBinError("");
    // setLoading(false);
    // setBinDetails(resp);

    ApiService2.get(
      `v1/batteries/${binNo}`,
      {},
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setBinError("");
        setLoading(false);
        setBinDetails(resp);
        navigate(`/bin/${resp.BIN}`);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 404) {
          setBinError(
            "Battery Information related to the selected BIN is not available"
          );
        } else if (err?.response?.status === 400) {
          setBinError("Invalid BIN format");
        } else if (err?.name == "AxiosError") {
          setBinError("Something went wrong, please try again later.");
        }
      });
  };

  const fetchAuditTrail = (binNo) => {
    setAuditTrailLoading(true);
    setAuditTrailData(null);
    ApiService2.get(
      `v1/batteries/${binNo}/history`,
      {},
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setAuditTrailLoading(false);
        setAuditTrailData(resp);
      })
      .catch((err) => {
        setAuditTrailLoading(false);
        if (err?.response?.status === 404) {
          setAuditTrailError(err);
        } else if (err?.response?.status === 400) {
          setAuditTrailError(err);
        } else if (err?.response?.status === 401) {
          setAuditTrailError("User is not authorised");
        } else if (err?.name == "AxiosError") {
          setAuditTrailError("Something went wrong, please try again later.");
        }
      });
  };

  const fetchRecordLevel = (Id) => {
    setRecordLevelLoading(true);
    setRecordLevelData(null);
    ApiService2.get(
      `v1/batteries/attributes/history`,
      {
        id: Id,
      },
      {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      }
    )
      .then((resp) => {
        setRecordLevelLoading(false);
        setRecordLevelData(resp);
      })
      .catch((err) => {
        setRecordLevelLoading(false);
        if (err?.response?.status === 404) {
          setRecordLevelError(err);
        } else if (err?.response?.status === 400) {
          setRecordLevelError(err);
        } else if (err?.response?.status === 401) {
          setRecordLevelError("User is not authorised");
        } else if (err?.name == "AxiosError") {
          setRecordLevelError("Something went wrong, please try again later.");
        }
      });
  };
  return (
    <AppContext.Provider
      value={{
        vinNumber,
        setVinNumber,
        reportData,
        fetchReportData,
        page,
        setPage,
        selectedFilters,
        setSelectedFilters,
        loading,
        fetchGraphBatteriesAddedCount,
        graphAddedCount,
        graphFailedCount,
        fetchGraphBatteriesFailedCount,
        processedCount,
        setProcessedCount,
        fetchBatteryProcessedCount,
        sourceProcessingStatistics,
        setSourceProcessingStatistics,
        yearProcessingStatistics,
        setYearProcessingStatistics,
        sourceProcessed,
        sourceFilter,
        setSourceFilter,
        timeProcessed,
        setSourceProcessed,
        setTimeProcessed,
        binDetails,
        binError,
        setBinError,
        fetchBinNumber,
        idToken,
        fetchIdTokenRequest,
        setBinNumber,
        binNumber,
        fetchBinDetails,
        authError,
        auditTrailData,
        fetchAuditTrail,
        auditTrailError,
        addedgraphError,
        failedgraphError,
        statsError,
        tableDataError,
        fetchAdminBinNumber,
        fetchAdminBinDetails,
        fetchRecordLevel,
        recordLevelError,
        recordLevelData,
        recordLevelLoading,
        auditTrailLoading,
        fetchBatteryData,
        batteryData,
        selectedDataFilters,
        setSelectedDataFilters,
        fetchBatteryFamilies,
        downloadCsvReport,
        familiesData,
        familyNumber,
        setFamilyNumber,
        familyDetails,
        fetchFamilyDetails,
        handleLogout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
