import { Grid } from "@mui/material";
import { Content } from "../../components/organisms/Content/Content";
import { styles } from "./styles";

export const Landing = () => {
  return (
    <Grid sx={styles.landing}>
      <Content />
    </Grid>
  );
};
