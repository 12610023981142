import { useIsAuthenticated } from "@azure/msal-react";
import { AppBar, Box, Grid, Toolbar } from "@mui/material";
import { BatteryPassport } from "../../atoms/BatteryPassport/BatteryPassport";
import { HorizontalSeperator } from "../../atoms/HorizontalSeperator/HorizontalSeperator";
import { LexusLogo } from "../../atoms/LexusLogo/LexusLogo";
import { LoginButton } from "../../atoms/LoginButton/LoginButton";
import { LogoutButton } from "../../atoms/LogoutButton/LogoutButton";
import { ToyotaLogo } from "../../atoms/ToyotaLogo/ToyotaLogo";
import { ToyotaLogo2 } from "../../atoms/ToyotaLogo2/ToyotaLogo2";
import { styles } from "./styles";

export const Navbar = () => {
  const isAuthenticated = useIsAuthenticated();

  const getStyle = (breakpoint, theme) => {
    return {
      display: "inline-block",
      [theme.breakpoints.down(breakpoint)]: {
        display: "none",
      },
    };
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        marginTop: "2px",
      }}
      px={"26px"}
    >
      <AppBar position="static" sx={styles.navbar} elevation={0}>
        <Toolbar>
          <Grid
            sx={{
              flexGrow: "1",
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <ToyotaLogo />
            <Box component="span" sx={(theme) => getStyle("sm", theme)}>
              <HorizontalSeperator />
            </Box>
            <Box component="span" sx={(theme) => getStyle("sm", theme)}>
              <BatteryPassport />
            </Box>
            <Box component="span" sx={(theme) => getStyle("sm", theme)}>
              <HorizontalSeperator />
            </Box>
            <Box component="span" sx={(theme) => getStyle("sm", theme)}>
              <ToyotaLogo2 />
            </Box>
            <Box component="span" sx={(theme) => getStyle("sm", theme)}>
              <LexusLogo />
            </Box>
          </Grid>
          {/* <AuthenticatedTemplate>
            <LogoutButton />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginButton />
          </UnauthenticatedTemplate> */}
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
