import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { UpdateTime } from "../../atoms/UpdateTime/UpdateTime";
import DownArrow from "./ACCORDIAN_Down-Arrow.png";
import source from "./Source_Icon.png";
import time from "./Update-Time_icon.png";
import user from "./Updated_by_icon.png";
import { styles } from "./styles";

export const AuditTrailDialog = (props) => {
  const { openDialog, setOpenDialog } = props;
  const [expandedAccordions, setExpandedAccordions] = useState(null);

  const {
    auditTrailData,
    auditTrailError,
    fetchRecordLevel,
    recordLevelData,
    recordLevelLoading,
  } = useAppContext();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAccordionChange = (index, trailData) => {
    if (index != expandedAccordions && (index || index == 0)) {
      fetchRecordLevel(trailData.Id);
    }
    setExpandedAccordions(index === expandedAccordions ? null : index);
  };

  // const handleAccordionClick = (id) => {
  //   // Toggle the state of the accordion when clicked
  //   setIsAccordionOpen(!isAccordionOpen);

  //   // If the accordion is being opened, fetch data
  //   if (!isAccordionOpen) {
  //     fetchRecordLevel(id);
  //   }
  // };
  // useEffect(() => {
  //   const tempFn = async () => {
  //     if (Object.keys(auditTrailData)?.length === 0) {
  //       setLoading(true);
  //       await fetchAuditTrail(binNo);
  //       setLoading(false);
  //     }
  //   };
  //   tempFn();
  // }, [auditTrailData]);

  // if (loading) {
  //   return (
  //     <Grid
  //       sx={{
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <CircularProgress />
  //     </Grid>
  //   );
  // }
  // if (auditTrailError) {
  //   return (
  //     <Grid
  //       sx={{
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Typography variant="h2">
  //         Error : {auditTrailError ? auditTrailError : "Something Went Wrong"}
  //       </Typography>
  //     </Grid>
  //   );
  // }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      // maxWidth="lg"
      fullWidth
      sx={styles.dialogBox}
    >
      <Typography
        sx={{
          backgroundColor: "#eb0a1e",
          color: "white",
          fontSize: "18px",
          lineHeight: "47px",
          paddingLeft: "15px",
        }}
      >
        Audit Trail
      </Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // rowGap: "1rem",
          margin: "1rem 2rem 1rem 2rem",
          // height: "100%",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "1rem",
            height: "80vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          {auditTrailError && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h2">
                No updates available for this record
              </Typography>
            </Grid>
          )}
          {auditTrailData?.map((trailData, index) => (
            <Accordion
              key={index}
              // expanded={expandedAccordions[index]}
              expanded={expandedAccordions === index}
              sx={{
                border: "none", // Remove default border
                boxShadow: "none", // Remove default box shadow
                "&:before": {
                  display: "none", // Hide default before pseudo-element
                },
              }}
            >
              <AccordionSummary
                onClick={() => {
                  handleAccordionChange(index, trailData);
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#666666",
                    borderRadius: "10px",
                    height: "80px",
                    width: "calc(100% - 60px)",
                    position: "relative",
                  }}
                  container
                >
                  <UpdateTime
                    width={3}
                    image={time}
                    label="Update Time"
                    value={
                      trailData["Update Time"].slice(0, 10) +
                      "," +
                      trailData["Update Time"].slice(11, 16)
                    }
                    sx={{
                      borderLeft: "4px solid #6ac3fd",
                      // width: "33%",
                    }}
                  />
                  <UpdateTime
                    width={3}
                    image={source}
                    label="Source"
                    value={trailData.Source}
                    // style={{ width: "33%" }}
                  />
                  <UpdateTime
                    width={6}
                    image={user}
                    label="Updated by"
                    value={trailData["Updated by"]}
                    style={{
                      // width: "33%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                  <div
                    style={{
                      width: "110px",
                      height: "80px",
                      backgroundColor:
                        expandedAccordions === index ? "#6ac3fd" : "#de6868",
                      // backgroundColor: "#6ac3fd",
                      transform: "skew(-32deg)",
                      borderRadius: "8px",
                      marginRight: "-30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // border: "1px solid blue",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <div
                      style={{
                        transform: "skew(35deg)",
                      }}
                    >
                      <img
                        style={{
                          transform: `rotate(${
                            expandedAccordions === index ? -180 : 0
                          }deg)`,
                          transition: "transform 200ms linear",
                        }}
                        src={DownArrow}
                        alt=""
                      />
                    </div>
                  </div>
                </Grid>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  borderColor: "eeeeee",
                  backgroundColor: "#f5f5f5",
                  margin: "0 24px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "18px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Type of update
                  </Typography>
                  {recordLevelLoading && (
                    <Grid>
                      <Skeleton animation="wave" height={80} />
                      <Skeleton animation="wave" height={80} />
                    </Grid>
                  )}
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "300px",
                      overflow: "auto",
                      rowGap: "8px",
                    }}
                  >
                    {recordLevelData?.map((recordData) => (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "flex-start",
                          borderRadius: "8px",
                          backgroundColor: "#0099ff",
                          color: "white",
                          minHeight: "46px",
                          padding: "1rem",
                          // overflow: "auto",
                          // columnGap: "1rem",
                        }}
                        container
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            padding: "0 8px",
                            alignItems: "flex-start",
                            gap: "8px",
                          }}
                          sm={4}
                        >
                          <Typography sx={{ fontSize: "18px" }}>
                            Attribute Name
                          </Typography>
                          <Typography sx={{ fontSize: "18px" }}>:</Typography>
                          <Typography
                            sx={{ fontSize: "18px" }}
                            className="ellipses"
                          >
                            {recordData["Attribute Name"]}
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            padding: "0 8px",
                            alignItems: "flex-start",
                            gap: "8px",
                          }}
                          sm={4}
                        >
                          <Typography sx={{ fontSize: "18px" }}>
                            Previous Value
                          </Typography>
                          <Typography sx={{ fontSize: "18px" }}>:</Typography>
                          <Typography sx={{ fontSize: "18px" }}>
                            {recordData["Previous Value"].trim() !== "null"
                              ? recordData["Previous Value"]
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            padding: "0 8px",
                            alignItems: "flex-start",
                            gap: "8px",
                          }}
                          sm={4}
                        >
                          <Typography sx={{ fontSize: "18px" }}>
                            Updated Value
                          </Typography>
                          <Typography sx={{ fontSize: "18px" }}>:</Typography>
                          <Typography sx={{ fontSize: "18px" }}>
                            {recordData["Updated Value"]}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Dialog>
  );
};
