import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { styles } from "./styles";

import banner from "./banner.jpg";
import car from "./car.png";

export const DialogPop = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handlePreviousTab = () => {
    setSelectedTab((prevTab) => Math.max(prevTab - 1, 0));
  };

  const handleNextTab = () => {
    setSelectedTab((prevTab) => Math.min(prevTab + 1, 2));
  };
  // const getStyle = (breakpoint, theme) => {
  //   return {
  //     display: "inline-block",
  //     [theme.breakpoints.up(breakpoint)]: {
  //       display: "none",
  //     },
  //   };
  // };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={(theme) => ({
          ...styles.dialogBox,
          [theme.breakpoints.down(500)]: {
            "& .MuiPaper-root": {
              minHeight: "70vh",
            },
          },
        })}
      >
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={(theme) => ({
              "& .MuiTabs-indicator": {
                backgroundColor: "#eb0a1e",
              },
              [theme.breakpoints.down(500)]: {
                "& .MuiTab-root": {
                  // Target each tab
                  minWidth: "100%", // Set minimum width to 100%
                },
              },
              // textAlign: "right",
            })}
          >
            <Tab
              label="What is VIN ?"
              style={{
                textTransform: "capitalize",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
              }}
              sx={styles.vinLabel}
            ></Tab>
            <Tab
              label="Where do I find my VIN ?"
              style={{
                textTransform: "capitalize",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
              }}
              sx={styles.findLabel}
            ></Tab>
            <Tab
              label="Where do I find my VIN on the vehicle ?"
              style={{ color: "black" }}
              sx={(theme) => ({
                textTransform: "capitalize",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
                [theme.breakpoints.down(500)]: {
                  textTransform: "capitalize",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                },

                // textAlign: "right",
              })}
            ></Tab>
          </Tabs>
          <IconButton
            onClick={handlePreviousTab}
            disabled={selectedTab === 0}
            sx={(theme) => ({
              position: "absolute",
              top: "55%",
              left: "-5px",
              [theme.breakpoints.up("sm")]: {
                display: "none",
              },
            })}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={handleNextTab}
            disabled={selectedTab === 2}
            sx={(theme) => ({
              position: "absolute",
              top: "55%",
              right: "1px",
              [theme.breakpoints.up("sm")]: {
                display: "none",
              },
            })}
          >
            <NavigateNextIcon />
          </IconButton>
          {selectedTab === 0 && (
            <Grid
              container
              direction={"column"}
              gap={"1rem"}
              paddingTop={"1rem"}
            >
              <Grid item>
                <Typography>
                  A vehicle identification number, more commonly referred to as
                  a VIN, is a 17 character code that is unique to a vehicle.
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={styles.exTyp}>Example</Typography>
              </Grid>
              <Grid item>
                <Typography
                  component={"span"}
                  style={{
                    color: "red",
                    border: "1px solid red",
                    padding: "1rem",
                  }}
                  sx={styles.vinLabels}
                >
                  1HGBH41JXMN101867
                </Typography>
              </Grid>
            </Grid>
          )}
          {selectedTab === 1 && (
            <Grid
              container
              direction={"column"}
              gap={"1rem"}
              overflow={"hidden"}
              paddingTop={"1rem"}
            >
              <Grid
                item
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    height: "100%",
                    width: "100%",
                  },
                })}
              >
                <img src={banner} alt="banner" height={"80%"} width={"100%"} />
              </Grid>
              <Grid item>
                <Typography>
                  Open your glove compartment and look for your vehicle
                  registration documents.
                  <br />
                  <span style={{ color: "red" }}>
                    Your VIN should be indicated there.
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          {selectedTab === 2 && (
            <Grid
              container
              direction={"column"}
              gap={"1rem"}
              overflow={"hidden"}
              alignItems={"center"}
              paddingTop={"1rem"}
            >
              <Grid item>
                <img src={car} alt="car" height={"50%"} width={"100%"} />
              </Grid>
              <Grid item>
                <Typography>
                  <span style={{ color: "#eb0a1e" }}>1.</span> Front of engine
                  block (under bonnet)
                </Typography>
                <Typography>
                  <span style={{ color: "#eb0a1e" }}>2.</span> Front end of
                  frame (older cars)
                </Typography>
                <Typography>
                  <span style={{ color: "#eb0a1e" }}>3.</span> Driver's side
                  interior dash.
                </Typography>
                <Typography>
                  <span style={{ color: "#eb0a1e" }}>4.</span> Driver's side
                  door jamb.
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
