import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import { styles } from "./styles";

// const validationSchema = Yup.object().shape({
//   vinNumber: Yup.string()
//     .required("VIN number is required")
//     .matches(/^[0-9A-Za-z]{17}$/, "VIN Number must be a 17-digit alphanumeric"),
// });
export const SearchButton = (props) => {
  const { fetchBinNumber, setBinError } = useAppContext();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const isValid = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{17}$/;
    setBinError("");
    if (isValid.test(props.vinNumber)) {
      props.setValidVin(true);
      await fetchBinNumber(navigate);
      // navigate(`bin/${props.binNumber}`);
    } else {
      props.setValidVin(false);
    }
  };
  // useEffect(()=>{
  //   if(props.binNumber){
  //     navigate(`bin/${props.binNumber}`);

  //   }
  // },[props.binNumber])

  return (
    <>
      <Button size="small" sx={styles.search} onClick={handleSubmit}>
        Search
      </Button>
      {/* {binError && (
        <Grid
          sx={(theme) => ({
            position: "absolute",
            top: "50%",
            left: "calc(53% + 170px)",
            transform: "translateY(-24px)",
            [theme.breakpoints.down("sm")]: {
              left: "calc(50% - 165px)",
              top: "calc(50% + 42px)",
            },
            // gap: "16px",
            // margin: ".75rem 0 0 .75rem",

            // border: "1px solid white",
          })}
        >
          <Typography variant="h2">
            {binError ? binError : "Something Went Wrong"}
          </Typography>
        </Grid>
      )} */}
    </>
  );
};
