import { Grid, Typography } from "@mui/material";
import { DataUpload } from "../../components/organisms/DataUpload/DataUpload";
import { styles } from "./styles";

export const ManualDataUpload = () => {
  const headingUpload = "UPLOAD DOCUMENT";
  const headingAttribute = "UPLOAD BATTERY DATA ATTRIBUTES";

  return (
    <>
      <Grid sx={styles.manualDataUpload}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography color={"white"} variant="h5">
            Manual Data Upload
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              // gap: "1rem",
              height: "100%",
              paddingTop: "1rem",
              width: "99%",
            }}
          >
            <DataUpload heading={headingAttribute} type="xlsx" />
            <DataUpload heading={headingUpload} type="pdf" />
          </div>
        </div>
      </Grid>
    </>
  );
};
