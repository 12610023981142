import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TuneIcon from "@mui/icons-material/Tune";
import { Backdrop, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { SwitchButton } from "../../atoms/SwitchButton/SwitchButton";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DataGrid } from "@mui/x-data-grid";
import { PopupDataUpload } from "../../atoms/PopupDataUpload/PopupDataUpload";
import { DialogDataReport } from "../../molecules/DialogDataReport/DialogDataReport";

export const BatterData = () => {
  // const { REACT_APP_base_url } = process.env;

  const {
    batteryData,
    fetchBatteryData,
    page,
    setPage,
    loading,
    downloadCsvReport,
  } = useAppContext();
  // const { filterReportData, fetchFilterReportData } = useFilterReportContext();
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [loader, setLoader] = useState(true);
  const [checked, setChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      fetchBatteryData();
      setLoader(false);
    }
  }, [page, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const downloadReport = () => {
    if (batteryData.totalElements === 0) return;
    if (batteryData.totalElements <= 10000) {
      downloadCsvReport();
    } else {
      setUploadSuccess("failedExcedMessage");
      setShowPopup(true);
    }
  };

  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };
  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  //   const startDateFormatted = selectedDataFilters.startDate.split('-').reverse().join('/');
  //   const endDateFormatted = selectedDataFilters.startDate.split('-').reverse().join('/');

  const minTableCols = [
    {
      flex: 2,
      field: "BIN",
      headerName: "BIN",
    },
    {
      flex: 1,
      field: "FamilyDefinition",
      headerName: " Family Definition",
    },
    {
      flex: 1,
      field: "PartsNumber",
      headerName: "Parts Number",
    },
    {
      flex: 1,
      field: "BatteryManufacturerName",
      headerName: "Battery Manufacturer",
    },
    {
      flex: 1,
      field: "DateOfManufacture",
      headerName: "Date Of Manufacturing",
    },

    {
      flex: 1,
      field: "RatedCapacity",
      headerName: "Rated Capacity (Ah)",
    },
    {
      flex: 1,
      field: "Power",
      headerName: "Power (W)",
    },
    {
      flex: 1,
      field: "EUDoCIdentificationNo",
      headerName: "Doc ID No",
    },
  ];

  const maxTableCols = [
    {
      minWidth: 200,
      field: "BIN",
      headerName: "BIN",
    },
    {
      minWidth: 180,
      field: "Vin Link",
      headerName: "VIN Link",
    },
    {
      minWidth: 180,
      field: "FamilyDefinition",
      headerName: " Family Definition",
    },
    {
      minWidth: 160,
      field: "PartsNumber",
      headerName: "Parts Number",
    },
    {
      minWidth: 140,
      field: "BatteryManufacturerName",
      headerName: "Battery Manufacturer",
    },
    {
      minWidth: 140,
      field: "RegisteredTradeName",
      headerName: "Registered Trade Name",
    },
    {
      minWidth: 140,
      field: "PostalAddress",
      headerName: "Address",
    },
    {
      minWidth: 140,

      field: "Contact",
      headerName: "Contact",
    },
    {
      minWidth: 140,

      field: "BatteryCategory",
      headerName: "Battery Category",
    },
    {
      minWidth: 140,

      field: "PlaceOfManufacture",
      headerName: "Place of Manufacture",
    },
    {
      minWidth: 140,

      field: "DateOfManufacture",
      headerName: "Date Of Manufacturing",
    },

    {
      minWidth: 140,

      field: "Chemistry",
      headerName: "Chemistry",
    },
    {
      minWidth: 140,

      field: "CellResistance",
      headerName: "Cell Resistance (mOhm)",
    },
    {
      minWidth: 140,

      field: "PackResistance",
      headerName: "Pack Resistance (Ohm)",
    },
    {
      minWidth: 140,

      field: "InitialRoundTripEnergyEfficiency",
      headerName: "Initial Round Trip Energy Efficiency (%)",
    },
    {
      minWidth: 140,

      field: "LowestTemperatureForVoltage",
      headerName: "Lowest Temperature for Voltage (°C)",
    },
    {
      minWidth: 140,
      field: "HighestTemperatureForVoltage",
      headerName: "Highest Temperature for Voltage (°C)",
    },
    {
      minWidth: 140,

      field: "MinimalVoltage",
      headerName: "Minimal Voltage (V)",
    },
    {
      minWidth: 140,

      field: "NominalVoltage",
      headerName: "Nominal Voltage (V)",
    },
    {
      minWidth: 140,

      field: "MaximumVoltage",
      headerName: "Maximum Voltage (V)",
    },

    {
      minWidth: 140,

      field: "UsableOfExtinguishingAgent",
      headerName: "Usable Of Extinguishing Agent",
    },
    {
      minWidth: 140,

      field: "RatedCapacity",
      headerName: "Rated Capacity (Ah)",
    },
    {
      minWidth: 140,

      field: "CapacityFade",
      headerName: "Capacity Fade (%)",
    },
    {
      minWidth: 140,

      field: "Power",
      headerName: "Power (W)",
    },
    {
      minWidth: 140,

      field: "PowerFade",
      headerName: "Power Fade (%)",
    },
    {
      minWidth: 140,

      field: "InternalResistance",
      headerName: "Internal Resistance (Ohm)",
    },
    {
      minWidth: 140,

      field: "InternalResistanceIncrease",
      headerName: "Internal Resistance Increase (%)",
    },
    {
      minWidth: 140,

      field: "EnergyRoundTripEfficiency",
      headerName: "Energy Round Trip Efficiency (%)",
    },
    {
      minWidth: 140,

      field: "EnergyRoundTripEfficiencyFade",
      headerName: "Energy Round Trip Efficiency Fade (%)",
    },
    {
      minWidth: 140,

      field: "NumberOfChargeDischargeCycles",
      headerName: "Number Of Charge Discharge Cycles ",
    },
    {
      minWidth: 140,

      field: "ExpectedLifeTime",
      headerName: "Expected LifeTime (Years)",
    },
    {
      minWidth: 140,

      field: "CadmiumAbovePoint002percent",
      headerName: "Cadmium Above Point 002percent",
    },
    {
      minWidth: 140,

      field: "LeadAbovePoint004percent",
      headerName: "Lead Above Point 004percent",
    },
    {
      minWidth: 140,

      field: "EUDoCIdentificationNo",
      headerName: "Doc ID No",
    },

    {
      minWidth: 140,

      field: "CreationDate",
      headerName: "Creation Date",
    },
  ];
  return (
    <Grid
      container
      sx={{
        height: "100%",
        overflow: "auto",
        // border: "1px solid white",
      }}
    >
      <Grid
        container
        direction="column"
        // gap={"8px"}
        sx={{
          position: "relative",
          // height: "100%",
          // border: "1px solid white",
          padding: "0 1.625rem 0 0",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          // paddingLeft: "-3rem",
        }}
      >
        <Grid item>
          <Typography style={{ color: "white", fontSize: "1.5rem" }}>
            Battery Data Report
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            sx={{ marginBottom: "1rem" }}
          >
            <Grid item>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Tooltip title={<h2>Filter</h2>}>
                  <IconButton onClick={handleFilterDialogOpen}>
                    <TuneIcon style={{ color: "#eb0a1e", fontSize: "3rem" }} />
                  </IconButton>
                </Tooltip>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                    }}
                  >
                    Total Battery Records :
                  </Typography>
                  <Typography
                    sx={{
                      color: "#0099ff",
                    }}
                  >
                    {batteryData.totalElements}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid sx={{ display: "flex", gap: "1rem" }}>
                <SwitchButton
                  fetchBatteryData={fetchBatteryData}
                  checked={checked}
                  setChecked={setChecked}
                />

                <Tooltip title={<div>Download Data</div>}>
                  <FileDownloadIcon
                    onClick={downloadReport}
                    style={{
                      color: "#ffff",
                      fontSize: "46px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            overflowX: "auto",
          }}
        >
          <DataGrid
            rows={
              batteryData?.content?.length
                ? batteryData?.content
                : [
                    { id: "1" },
                    { id: "2" },
                    { id: "3" },
                    { id: "4" },
                    { id: "5" },
                    { id: "6" },
                  ]
            }
            columns={checked ? maxTableCols : minTableCols}
            sx={{
              background: "white",
              height: "482px",
              "& .MuiDataGrid-columnSeparator": { display: "none" },
              "& .MuiDataGrid-columnHeader": {
                background: "red",
                color: "white", // Text color for header fields
                border: "none",
              },
              "& .MuiDataGrid-columnHeader div": {
                height: "48px",
              },
              "& .MuiDataGrid-row": {
                color: "black", // Text color for rows
              },
              "& .MuiDataGrid-scrollbarHorizontal": {
                height: "8px", // Adjust the height of the horizontal scrollbar here
              },
              border: 0,
            }}
            disableColumnSorting
            disableColumnResize
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            pagination
            paginationMode="server"
            rowCount={
              batteryData?.totalElements ? batteryData?.totalElements : 0
            }
            page={page}
            pageSize={rowsPerPage}
            onPageChange={handleChangePage}
            loading={loading}
            rowsPerPageOptions={[7]}
          />
        </Grid>
        <DialogDataReport
          openFilterDialog={openFilterDialog}
          handleFilterDialogClose={handleFilterDialogClose}
        />
        <Backdrop open={openFilterDialog} onClick={handleFilterDialogClose} />
      </Grid>
      <PopupDataUpload
        open={showPopup}
        setOpen={setShowPopup}
        type={
          uploadSuccess == "pass"
            ? "success"
            : uploadSuccess == "failedTemplate"
            ? "failedTemplate"
            : uploadSuccess == "failedExcedMessage"
            ? "failedExcedMessage"
            : "failed"
        }
      />
    </Grid>
  );
};
